/* Estilos para el Popup de Login y Registro */
.loginBase {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 999;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(6px);
}

/* Contenedor principal con dos columnas */
.containerLoginBase {
    display: flex;
    width: 60%;
    background-color: #16151E;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    min-height: 65%;
}

/* Contenedor izquierdo con la imagen de fondo */
.imageContainer {
    width: 45%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position-y: 20%;
}

/* Aplica la imagen de fondo solo al contenedor izquierdo */
.containerLoginBase.login .imageContainer {
    background-image: url('../../img/backgroundLogin.png');
}

.containerLoginBase.register .imageContainer {
    background-image: url('../../img/backgroundRegister.png');
}

/* Columna derecha con el formulario */
.subContainerLoginBase {
    width: 55%;
    height: 100%;
    background: rgb(32,49,54);
    background: linear-gradient(60deg, rgba(32,49,54,1) 10%, rgba(58,44,73,1) 120%);
    padding: 50px;
    color: white;
    display: flex;
    flex-direction: column;
    text-align: center;
    height: auto;
    justify-content: center;
}


/* Título del formulario */
.titleLoginRegister {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 10px !important;
}

/* Subtítulo */
.titleLoginRegister h4 {
    font-size: 1rem;
    font-weight: 400;
    opacity: 0.8;
    margin-bottom: 20px;
}

/* Campos de entrada */
.containerInformationLogin input {
    width: 100%;
    height: 40px;
    border: none;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 2px !important;
    margin-top: 2px !important;
    background: rgba(255, 255, 255, 0.2);
    color: white;
    font-size: 16px;
}

.containerInformationLogin input::placeholder {
    color: rgba(255, 255, 255, 0.6);
}

/* Botón de inicio de sesión */
.apiLoginButton {
    width: 100%;
    height: 45px;
    background-color: #47FFBD;
    color: #16151E;
    font-size: 16px;
    font-weight: 700;
    border: none;
    border-radius: 5px;
    margin-top: 15px;
    cursor: pointer;
    transition: 0.3s;
}

.apiLoginButton:hover {
    background-color: #35DDA5;
}

/* Botón para crear cuenta */
.createAccountButton {
    width: 100%;
    height: 45px;
    background-color: transparent;
    color: white;
    font-size: 16px;
    font-weight: 700;
    border: 1px solid white;
    border-radius: 5px;
    margin-top: 10px;
    cursor: pointer;
    transition: 0.3s;
}

.createAccountButton:hover {
    background-color: white;
    color: #16151E;
}

/* Estilos para el enlace de "Olvidé mi contraseña" */
.forgotPassword {
    text-align: right;
    font-size: 12px;
    margin-bottom: 15px;
}

.forgotPassword a {
    color: white;
    text-decoration: underline;
}

/* Botón de cerrar */
.closeLogin {
    position: absolute;
    top: 20px;
    right: 20px;
    background: rgba(255, 255, 255, 0.2);
    color: white;
    font-size: 18px;
    font-weight: bold;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.3s;
}

.closeLogin:hover {
    background: rgba(255, 255, 255, 0.5);
}


/* Ajustes para móviles */
@media only screen and (max-width: 768px) {
    .containerLoginBase {
        flex-direction: column;
        width: 90%;
        height: auto;
    }

    .imageContainer {
        display: none; /* Oculta la imagen en pantallas pequeñas */
    }

    .subContainerLoginBase {
        width: 100%;
        padding: 30px;
    }
}

.checkboxContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 15px 0;
}

.checkboxLabel {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: white;
    margin-bottom: 8px;
    cursor: pointer;
    gap: 5px;
}

.checkboxLabel input {
    appearance: none;
    width: 16px;
    height: 16px;
    border: 2px solid white;
    border-radius: 3px;
    margin-right: 0;
    margin-bottom: 0 !important;
    position: relative;
    cursor: pointer;
}

.checkboxLabel input:checked {
    background-color: #47FFBD;
    border: 2px solid #47FFBD;
}

.checkboxLabel input:checked::after {
    content: "";
    position: absolute;
    left: 4px;
    width: 5px;
    height: 9px;
    border: solid #16151E;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.termsAndConditions a {
    text-decoration: underline;
    color: #47FFBD;
    font-weight: bold;
}

.backToLogin {
    margin-top: 15px;
    text-align: center;
}

.backToLogin button {
    color: white;
    text-decoration: underline;
    cursor: pointer;
    font-size: 14px;
}

.separator {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 15px 0;
}

.separator::before,
.separator::after {
    content: "";
    flex: 1;
    height: 1px;
    background: rgba(255, 255, 255, 0.2);
    margin: 0 10px;
}

.separator span {
    color: rgba(255, 255, 255, 0.6);
    font-size: 14px;
    font-weight: bold;
}

.accessEvents{
    margin-bottom: 15px !important;
}

.profile-container {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    background: rgba(22, 21, 30, 0.9);
    border-radius: 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    width: 150px;
    text-align: center;
    padding: 10px 0;
    z-index: 10;
  }
  
  .logout-button {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 14px;
    padding: 10px;
    width: 100%;
    text-align: center;
  }
  
  .logout-button:hover {
    background: rgba(255, 255, 255, 0.2);
  }
  

  /* Validations */

.login-input {
    width: 100%;
    height: 40px;
    border: none;
    border-radius: 5px;
    padding: 10px;
    background: rgba(255, 255, 255, 0.2);
    color: white;
    font-size: 16px;
    border: 2px solid transparent;
    transition: 0.3s;
}

.login-input:focus {
    border: 2px solid #47FFBD;
    outline: none;
}

.input-error {
    border: 2px solid #FF6B6B !important;
    background: rgba(255, 107, 107, 0.2);
}

.error-message {
    color: #FF6B6B;
    font-size: 10px;
    min-height: 18px;
    padding-left: 5px;
    text-align: left;
    visibility: hidden;
}

.inputContainer.has-error .error-message {
    visibility: visible;
}
