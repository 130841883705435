body {
    color: white;
}

.container-nosotros {
    width: 80%;
    margin: auto;
    padding: 80px 0;
}

.title-bold-green {
    color: #47ffbd;
    font-weight: 600;
}

.nosotros .title-bold {
    font-weight: 600;
}


.nosotros h3 {
    font-size: 37px;
    line-height: 40px;
    font-weight: 600;
    color: white;
    margin-bottom: 20px !important;
}

.nosotros h3 img {
    margin-bottom: 20px !important;
    width: 20%;
}

.nosotros p {
    font-size: 18px;
    line-height: 30px;
    font-weight: 400;
    color: white;
}

.nosotros h5 {
    font-size: 25px;
    line-height: 35px;
    font-weight: 400;
    color: white;
}

.container-one-nosotros {
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: space-between;
}

.container-one-nosotros div {
    width: 48%;
}

.container-one-nosotros div:last-child {
    display: flex;
    justify-content: end;
}

.container-one-nosotros div:last-child img:first-child {
    margin-right: 50px;
}

.container-one-nosotros p {
    width: 90%;
}


.typesNosotros {
    display: flex;
    justify-content: space-between;
    margin-top: 5rem;
}

.typesNosotros img {
    width: 15%;
}

/* Section Two*/
.section-two {
    position: relative;
}

.container-cards {
    margin: 5rem auto 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    gap: 30px;
}

.sub-container-card {
    position: relative;
    border-radius: 24.58px;
    padding: 40px 20px;
    width: 45%;
}

.sub-container-card h5 {
    font-size: 28px;
}

.sub-container-card:nth-of-type(1) {
    background: linear-gradient(251.15deg,
            rgba(232, 232, 232, 0.24) 0%,
            rgba(226, 226, 226, 0.12) 100%),
        url("../../img/background-green.png");
    background-size: cover;
    border: 1px solid #47ffbd;
}

.sub-container-card:nth-of-type(2) {
    background: linear-gradient(251.15deg,
            rgba(232, 232, 232, 0.24) 0%,
            rgba(226, 226, 226, 0.12) 100%),
        url("../../img/background-violet.png");
    background-size: cover;
    border: 1px solid #cf74f5;
}

.sub-container-card:nth-of-type(1) .icon-section-two {
    background: #47ffbd;
}

.sub-container-card:nth-of-type(2) .icon-section-two {
    background: #cf74f5;
}

.icon-section-two {
    position: absolute;
    top: -30px;
    right: calc(50% - 30px);
    padding: 10px;
    border-radius: 100px;
    width: 40px;
    height: 40px;
}

.container-section-two-information {
    margin: 8rem auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 3rem;
}

.container-section-two-information h3 {
    width: 50%;
    margin-bottom: 4rem;
}

.container-section-two-information h2 {
    margin-top: 4rem;
}

.sub-container-section-two-information {
    flex-direction: column;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 10rem 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 94%;
    background-position: left;
}

.sub-container-section-two-information p {
    width: 60%;
    margin-bottom: 2rem;
    font-size: 22px;
}

.title-sub-container-two {
    font-size: 30px !important;
}

.information-background {
    width: auto;
    margin: auto;
    text-align: center;
    position: relative;
}

.information-background p {
    width: 100%;
    margin-bottom: 1rem;
    color: rgba(9, 9, 9, 1);
    z-index: 3;
    font-family: "Avenir Black";
    font-weight: 800;
    font-size: 18px;
    z-index: 4;
    position: relative;
}

.green-background {
    background-color: #47ffbd;
}

.violet-background {
    background-color: #cf74f5;
}

.register-button {
    background-color: #47FFBD;
    border: 1px solid #47FFBD;
    border-radius: 10px;
    padding: 15px 25px;
    color: #080808;
    transition: .5s all ease-in-out;
    cursor: pointer;
    margin-top: 20px;
}

.register-button:hover {
    background-color: transparent;
    color: #47FFBD;
}

.green-background,
.violet-background {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 35px;
    /* left: calc(100% - 302px); */
    z-index: 1;
    right: -6px;
    padding: 0 10px;
    bottom: 15px;
    transform: rotate(-1deg);
}

.gradient-four {
    position: absolute;
    transform: rotate(180deg);
    width: 30%;
}

/* Section three */
.container-section-three {
    position: relative;
}

.blur-violet {
    position: absolute;
    position: absolute;
    top: -40px;
    width: 30%;
}

.section-three {
    width: 100%;
    margin: auto;
    padding: 0rem 0;
}

.aboutUs {
    display: flex;
    justify-content: space-between;
    background: linear-gradient(91.31deg,
            rgba(255, 255, 255, 0.14) -10.17%,
            rgba(196, 196, 196, 0.14) 119.02%);
    backdrop-filter: blur(35.0938px);
    border-radius: 9.23521px;
    padding: 5rem 5rem;
}

.aboutUs-information h5 {
    margin-bottom: 2rem;
    font-family: "Avenir Black";
}

.aboutUs-information p {
    font-style: italic;
    margin-left: 10rem;
}

.socios {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 5rem 0 0;
}

.socios-container {
    margin: 3rem 0;
    flex-direction: column;
    align-items: center;
    display: flex;
    justify-content: space-evenly;
    text-align: center;
    width: 100%;
}

.socios-container:nth-of-type(2) {
    flex-direction: unset;
    justify-content: space-between;
    width: 90%;
}

.socio {
    display: flex;
    justify-content: space-between;
    align-items: start;
}

.socio img {
    margin-top: 5px;
    margin-right: 10px;
}

.socio div p {
    font-family: "Avenir Black";
}

.socio div span {
    font-family: "Avenir Book";
    font-size: 18.6762px;
    line-height: 26px;
    color: #ffffff;
    opacity: 0.7;
}

/* Mobile */
@media only screen and (max-width: 700px) {
    .container-nosotros{
        padding: 30px 0 0 0;
        width: 85%;
    }
    .nosotros h3 img{
        width: 30%;
        margin-bottom: 10px !important;
    }
    .nosotros h3 {
        font-size: 25px;
        line-height: 28px;
    }

    .nosotros p {
        font-size: 14px;
        line-height: 19px;
    }

    .container-one-nosotros div {
        width: 100%;
    }

    .typesNosotros {
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 20px;
    }

    .typesNosotros img {
        width: 40%;
        margin: 5px;
    }

    .container-cards {
        flex-direction: column;
        margin: 3rem auto 0;
    }

    .sub-container-card {
        width: 100%;
        padding: 20px 10px;
    }
    .sub-container-card h5 {
        font-size: 18px;
        line-height: 19px;
    }
    .container-section-two-information {
        margin: 4rem auto 3rem;
    }

    .sub-container-card:nth-of-type(1){
        margin-bottom: 1rem;
    }

    .socios{
        margin: 2rem 0;
    }
    .nosotros h5{
        font-size: 20px;
        line-height: 22px;
        text-align: center;
    }

    .container-section-two-information h3 {
        width: 100%;
    }

    .aboutUs {
        padding: 0;
        background: transparent;
    }

    .aboutUs-information p {
        margin-left: 0;
        margin-top: 20px;
        text-align: center;
    }

    .socios-container {
        flex-direction: column !important;
        margin: 1rem 0 0;
    }

    .socios-container:last-child{
        margin-top: 50px !important;
    }

    .socios-container img {
        margin-bottom: 30px;
        width: 170px;
    }
}