body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.title-mobile-index h3 {
  color: white;
}

.title-mobile-index p {
  color: rgba(255, 255, 255, 0.6);
}

.title-mobile-index {
  position: relative;
  width: 90%;
  margin: 0 auto 5px auto;
}

.title-mobile-index h3 {
  font-size: 18px;
  line-height: 29px;
  letter-spacing: 0.03em;
  text-align: center;
  font-weight: 600;
}

.title-mobile-index h3 span {
  color: #47FFBD;
  ;
}

.title-mobile-index p {
  font-size: 17px;
  line-height: 17px;
  letter-spacing: 0.03em;
  color: rgba(255, 255, 255, 0.6);
}

.secondaryBanners .desktopElement {
  margin: 1.25% auto;
  width: 94%;
}

.bannerIntendencia {
  margin-bottom: 1em;
}

a:hover {
  color: unset !important;
}

.title-mobile-index+.container-searchHeader {
  display: none;
}

.banners-background {
  margin-top: 2rem;
}

/* Mobile */
@media only screen and (max-width: 700px) {
  .background-img-home .headerDummy {
    height: 90px !important;
  }

  .title-mobile-index+.container-searchHeader {
    display: block;
  }
}