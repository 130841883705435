.subContainerFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    margin: auto;
    padding: 3em 0;
}

.linksFooter,
.linksRedesFooter {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.linksFooter a, .linksFooter button {
    color: white;
    text-decoration: none;
    font-size: 16px;
    text-align: center;
    letter-spacing: 0.03em;
    transition: .5s all ease-in-out;
    margin: 0 15px;
}

.linksFooter a:hover {
    color: #47FFBD !important;
}

.linksFooter button {
    background: none;
    border: 1px solid white;
    padding: 10px 20px;
    border-radius: 100px;
    cursor: pointer;
}

.linksFooter button:hover {
    color: #47FFBD !important;
    border: 1px solid #47FFBD;
}

.linksRedesFooter a img {
    transition: .5s all ease-in-out;
}

.linksRedesFooter a {
    margin: 0 15px;
}

.linksRedesFooter a img:hover {
    filter: brightness(0) saturate(100%) invert(96%) sepia(13%) saturate(2071%) hue-rotate(80deg) brightness(99%) contrast(103%);
}

.createEvent:hover {
    border: 1px solid #47FFBD;
}

.lineSeparatorFooter {
    width: 100%;
    height: 1px;
    background-color: white;
    opacity: 50%;
}

.copyright {
    width: 90%;
    margin: auto;
    padding: 1em 0;
    display: flex;
    justify-content: space-between;
}

.copyright span {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    align-items: center;
    letter-spacing: 0.03em;
    color: white;
}



/* Mobile */
@media only screen and (max-width: 700px) {
    .subContainerFooter {
        flex-direction: column;
    }

    .linksFooter {
        flex-direction: column;
    }

    .logoFooter {
        margin-bottom: 30px;
    }

    .linksFooter a, .linksFooter button {
        margin-bottom: 30px !important;
    }
}


/* Ipad Mini & Air*/
@media only screen and (min-width: 700px) and (max-width: 820px) {
    .logoFooter {
        width: 80px;
    }

    .linksFooter a, .linksFooter button {
        font-size: 15px;
    }

    .copyright span {
        font-size: 10px;
    }

    .subContainerFooter {
        flex-direction: column;
    }

    .linksFooter {
        margin: 15px 0;
    }
}


/* Ipad Pro */
@media only screen and (min-width: 821px) and (max-width: 1628px) {
    .subContainerFooter {
        flex-direction: column;
    }

    .linksFooter {
        margin: 15px 0;
    }
}