*{
  padding: 0;
  margin: 0;
}
@font-face {
  font-family: "Avenir Black";
  src: url('/src/font/Avenir-Black.eot');
  src: url('/src/font/Avenir-Black.svg#LeSS') format('svg'),
      url('/src/font/Avenir-Black.ttf') format('truetype'),
      url('/src/font/Avenir-Black.woff') format('woff'),
      url('/src/font/Avenir-Black.eot?#iefix') format('embedded-opentype');
  font-weight: bold;
  font-display: block;
  font-style: normal;
}
@font-face {
  font-family: "Avenir Book";
  src: url('/src/font/Avenir-Book.eot');
  src: url('/src/font/Avenir-Book.svg#LeSS') format('svg'),
      url('/src/font/Avenir-Book.ttf') format('truetype'),
      url('/src/font/Avenir-Book.woff') format('woff'),
      url('/src/font/Avenir-Book.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-display: block;
  font-style: normal;
}
h1, h2, .title-frequent-questions{
  font-family: "Avenir Black" !important;
}
h5, span, p, ::placeholder, button, li, ul, a, input{
  font-family: "Avenir Book" !important;
  margin-bottom: 0 !important;
}
a{
  text-decoration: none;
}
input:focus-visible {
  outline: transparent auto 1px;
}
.app{
  overflow: hidden;
  background: #16151E;
}
h1, h2, h3, h4, h5, h6, p{
  margin-bottom: 0 !important;
}
button, html [type="button"], [type="reset"], [type="submit"]{
  -webkit-appearance: unset !important;
}

.destkopElement{
  display: block;
}
.mobileElement{
  display: none !important;
}

/* Mobile */
@media only screen and (max-width: 700px) {
  .background-img-home{
   background: #16151E;
  }
  .desktopElement{
    display: none !important;
  }
  .mobileElement{
    display: block !important;
  }
}