.container-information {
  width: 99%;
  /* width: 98%; */
  margin: 1% auto;
  /* margin: 2% auto; */
  height: calc(100vh - 95px - 4%);
  /* height: calc(100vh - 95px - 8%); */
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  border-radius: 20px;
}

.sliderHomeRight, .sliderHomeLeft {
  position: absolute;
  z-index: 1;
  top: 0;
  width: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  visibility: hidden;
  transition: .5s all ease-in-out;
  background: none;
  cursor: pointer;
  border: unset;
  /* top: calc(50% - 10% / 2 ); */
  opacity: 0;
  filter: drop-shadow(0px 0px 5px rgba(0,0,0,0.25));
}

.sliderHomeRight {
  right: 0;
  padding-left: 25px;
  background: linear-gradient(90deg, rgba(0,0,0,0), rgba(0,0,0,0.375));
}

.sliderHomeLeft {
  left: 0;
  padding-right: 25px;
  background: linear-gradient(-90deg, rgba(0,0,0,0), rgba(0,0,0,0.375));
}

.slick-slider:hover .sliderHomeRight,
.slick-slider:hover .sliderHomeLeft {
  visibility: visible;
  opacity: 1;
}

#mobileElement{
  display: none;
}

.containerSliderIconAndType{
  align-items: center !important;
  flex-direction: unset !important;
}

.containerSliderIconAndType h5{
  margin-left: 10px;
  margin-bottom: 7px !important;
}
/* Estilos vista evento */

.container-event-information .container-start .container-information {
  width: 100%;
  border-radius: 0;
  height: calc(100vh - 95px);
}


.container-event-information .container-start .container-information .information {
  background-color: transparent;
  backdrop-filter: unset;
  padding: 0;
  bottom: 40px;
}

/*******************/
.band,
.bandMobile {
  position: absolute;
  z-index: -1;
  top: 0px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

.container-event-information .container-start .container-information .band,
.container-event-information .container-start .container-information .bandMobile {
  top: -10px !important;
}


.information {
  display: flex;
  justify-content: center;
  width: 100%;
  left: 0;
  position: absolute;
  bottom: 0;
  background: rgba(22, 21, 30, 0.6);
  backdrop-filter: blur(20px);
  padding: 10px 0;
  transition: bottom 1s ease-in-out;
}

.container-information-slider {
  width: 95%;
  display: flex !important;
  margin: auto;
  justify-content: space-between;
  align-items: center !important;
  padding-top: 0 !important;
}

.container-information-slider div {
  padding-top: 0 !important;
}

.container-information-slider div img {
  width: 50px;
  height: 50px;
  padding: 10px;
  margin: 10px;
  background: #1E1E2A;
  border-radius: 10px;
}

.theme {
  font-weight: 400;
  font-size: 21.2155px;
  line-height: 37px;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  color: #47ffbd;
}

.title {
  font-weight: 800;
  font-size: 40px;
  line-height: 50px;
  letter-spacing: 0.03em;
  color: #ffffff;
  max-width: 100%;
  display: -webkit-box;
  overflow: hidden;
  /* -webkit-line-clamp: 1; */
  /* -webkit-box-orient: vertical; */
}

.informationTheme {
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0.03em;
  color: #ffffff;
  opacity: 0.6;
  margin-left: 20px;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-top: 8px;
}

.information div {
  display: flex;
  align-items: center;
  padding-top: 1rem;
}

.information .container-information-slider+div {
  width: 90%;
}

.wantToGo {
  border: 1px solid transparent;
  box-shadow: 0px 3.31459px 5.80053px rgba(0, 0, 0, 0.05);
  text-decoration: none;
  font-weight: 800;
  font-size: 13.7128px;
  line-height: 19px;
  border-radius: 10px;
  padding: 10px 30px;
  transition: .5s all ease-in-out;
  min-width: 132px;
  text-align: center;
}

.theme.Música {
  color: #2DEAF7;
}

.wantToGo.Música {
  background: #2DEAF7;
  color: #16151e;
}

.theme.Cine {
  color: #47FFBD;
}

.wantToGo.Cine {
  background: #47FFBD;
  color: #16151e;
}

.theme.Teatro {
  color: #C20001;
}

.wantToGo.Teatro {
  background: #C20001;
  color: #16151e;
}

.theme.Otros.Eventos {
  color: #FF008A;
}

.wantToGo.Otros.Eventos {
  background: #FF008A;
  color: #16151e;
}

.theme.Fiestas {
  color: #C53AD5;
}

.wantToGo.Fiestas:hover {
  background: #C53AD5;
  color: #16151e;
}

.theme.Carnaval {
  color: #6E2CFF;
}

.wantToGo.Carnaval {
  background: #6E2CFF;
  color: #16151e;
}

.theme.Locación {
  color: #47FFBD;
}

.wantToGo.Locación {
  background: #47FFBD;
  color: #16151e;
}

.theme.Oferta.Gastronómica {
  color: #EA7506;
}

.wantToGo.Oferta.Gastronómica {
  background: #EA7506;
  color: #16151e;
}

.theme.Artista {
  color: #E7D005;
}

.wantToGo.Artista {
  background: #E7D005;
  color: #16151e;
}

.wantToGo:hover {
  background: transparent !important;
  color: white !important;
  border: 1px solid white;
}

/* Dots slider */
.slick-dots {
  display: block;
  z-index: 10;
  bottom: 30px !important;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
}

.slick-dots li {
  width: 60px !important;
}

.slick-dots li button:before {
  width: 60px !important;
  background: #4F4F51 !important;
  height: 10px !important;
  opacity: 1 !important;
  color: transparent !important;
  border-radius: 100px;
}

.slick-dots li.slick-active button:before {
  width: 60px !important;
  height: 10px;
  background-color: #47FFBD !important;
  color: transparent !important;
  border-radius: 100px;
}

.cocardaBankSliderHome {
  width: 150px;
  position: absolute;
  left: 0;
  z-index: 100;
  height: calc(100vh - 95px);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.cocardaBankSliderHome img {
  width: 100%;
  height: 70px;
  object-fit: cover;
  margin-bottom: 10px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.cocardaBankSliderHome img:last-child {
  margin-bottom: 0;
}


/* Mobile */
@media only screen and (max-width: 1200px) and (min-width: 701px)  {
  .background-img-home .slick-slider{
    height: auto !important;
  }
  .container-event-information .container-start .container-information .information {
    bottom: 140px !important;
  }
}

/* Mobile */
@media only screen and (max-width: 1200px) {
  #mobileElement{
    display: block;
  }
  .cocardaBankSliderHome {
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    top: 0;
    z-index: 100;
    height: fit-content;
    flex-direction: unset;
  }

  .sliderHomeRight, .sliderHomeLeft {
    display: none;
  }

  /* Estilos vista evento */

  .container-event-information .container-start .container-information {
    height: 100% !important;
  }
  .container-event-information .container-start .container-information .information {
    bottom: 70px;
  }
  .container-event-information .container-start .container-information .information .container-information-slider {
    margin-left: 0;
  }

  /*******************/

  .cocardaBankSliderHome img {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 20px;
    margin: 0 5px;
    border-bottom-right-radius: 20px;
    height: 60px;
    width: 60px;
  }

  .container-information {
    width: 95%;
    margin: 10px auto;
    height: auto !important;
    overflow: hidden;
    /* background: linear-gradient(180deg, rgba(22, 21, 30, 0) 35.5%, #16151E 91.04%), linear-gradient(0deg, rgba(8, 8, 20, 0.1), rgba(8, 8, 20, 0.1)); */
  }

  .slick-slider .slick-track .container-information .containerBandMobile {
    height: 330px;
  }

  .title {
    line-height: 30px;
    font-size: 25px;
    margin-bottom: 0;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-width: 100%;
  }

  .theme {
    font-size: 13px;
    line-height: 20px;
    margin-bottom: 0;
  }

  .slick-dots {
    display: none !important;
  }

  .slick-list .slick-track .slick-slide div .container-information .information {
    position: unset;
    width: 100%;
    left: 0;
    /* padding: 0 20px 20px 20px;*/
    backdrop-filter: unset;
  }

  .container-information-slider {
    flex-direction: column;
    justify-content: space-evenly;
    align-items: start !important;
  }

  .container-information-slider div:first-child {
    flex-direction: column;
    align-items: flex-start;
  }

  .container-information-slider div img {
    margin: 0 0 10px;
    width: 40px;
    height: 40px;
    padding: 10px;
  }

  .bandMobile {
    height: 100% !important;
    width: 100%;
    position: relative;
    border-radius: 20px;
  }

  .informationTheme {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 0;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 0;
    margin-top: 10px;
  }

  .wantToGo {
    font-size: 11px;
    margin-top: 10px !important;
  }

  .container-information-slider div:last-child {
    width: 100%;
  }
}