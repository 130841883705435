button {
    background-color: transparent;
    border: none;
}

.button {
    color: white;
    border: 1px solid white;
    border-radius: 100px;
    padding: 10px 20px;
    font-family: "Avenir Book";
    height: 40px;
    font-size: 13px;
    line-height: 12px;
    margin: 0 5px;
    cursor: pointer;
    transition: .5s all ease-in-out;
}

.button-click {
    color: #434249 !important;
    background-color: #47FFBD;
    font-family: "Avenir Book";
    border: 1px solid #47FFBD;
    border-radius: 100px;
    padding: 10px 20px;
    height: 40px;
    font-size: 13px;
    margin: 0 5px;
    line-height: 12px;
    cursor: pointer;
    font-weight: 900;
}

.button:hover {
    color: #434249 !important;
    border: 1px solid #47FFBD;
    background-color: #47FFBD;
}


/* Mobile */
@media only screen and (max-width: 490px) {
    .button:hover {
        color: white !important;
        background-color: unset;
    }
}

/* Ipad Pro */
@media only screen and (min-width: 821px) and (max-width: 1628px) {
    .button:hover {
        color: white !important;
        background-color: unset;
    }
  }