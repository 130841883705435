.question{
    border-top: 1px solid white;
    padding: 20px 0;
}
.header-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.header-question div {
    display: flex;
    align-items: center;
}

.buttonQuestionNoOpen, .buttonQuestionOpen{
    background-color: transparent;
    border: unset;
    width: 100%;
    cursor: pointer;
    overflow: hidden;
}
.buttonQuestionNoOpen .body-question{
    display: none;
}

.buttonQuestionOpen .body-question{
    display: block;
}

.buttonQuestionOpen .header-question button img{
    transform: rotate(90deg);
    cursor: pointer;
}

.numberQuestion {
    font-size: 16px;
    line-height: 16px;
    font-weight: 800;
    color: #16151E;
    background-color: #47FFBD;
    border-radius: 100px;
    padding: 8px 12px;
}

.titleQuestion {
    font-size: 16px;
    line-height: 22px;
    color: #FFFFFF;
}

.header-question button {
    background: transparent;
    border: transparent;
    transform: rotate(270deg);
}
.body-question{
    margin-right: 50px;
    margin-top: 20px;
}
.body-question p{
    color: white;
    text-align: initial;
}