.containerEvents {
  width: 100%;
  margin-left: auto;
  padding: 1em 0;
  position: relative;
}

.background-hide {
  position: absolute;
  width: 3%;
  height: 100%;
  background: #16151E;
  z-index: 2;
}

.searchResults .background-hide {
  display: none;
}

.sliderEvents {
  width: calc(97% + 25px);
  margin-left: auto;
}

.slick-list {
  padding: 0;
}

.containerTitleTypeEvent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 2em;
  width: 94%;
  margin: auto;
}

.SubContainerTitleTypeEvent {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 30px;
  line-height: 20px;
  white-space: nowrap;
}

.containerTitleTypeEvent img {
  background: #1e1e2a;
  padding: 10px;
  margin-right: 10px;
  border-radius: 10px;
  height: 45px;
  width: 45px;
}

.buttonTitleTypeEvent {
  display: flex;
  align-items: center;
}

.titleTypeEvent {
  color: white;
  font-size: 25px;
}

.subTitleTypeEvent {
  color: #ffffff;
  opacity: 0.6;
  font-size: 25px;
}

.lineTypeEvent {
  height: 25px;
  width: 2px;
  margin: 0 7px;
  background: white;
}

/* .events:nth-of-type(3) .lineTypeEvent{
  display: none;
} */

.events.restaurantsSlider .sliderSponsors {
  display: flex;
  gap: 1rem;
}

.events.restaurantsSlider .sliderSponsors button {
  background: none;
  height: 41px;
  width: fit-content;
  padding: 0px 20px;
  border: 1px solid #A5A5AA;
  border-radius: 20px;
  white-space: nowrap;
  cursor: pointer;
  opacity: .3;
  transition: .5s ease;
}

.events.restaurantsSlider .sliderSponsors .sponsorButton.selected.sponsorSantander {
  background-color: #EC0000;
  border-color: #EC0000;
}
.events.restaurantsSlider .sliderSponsors .sponsorButton.selected.sponsorScotiabank {
  background-color: #EC111A;
  border-color: #EC111A;
}
.events.restaurantsSlider .sliderSponsors .sponsorButton.selected.sponsorItaú {
  background-color: #FF6201;
  border-color: #FF6201;
}
.events.restaurantsSlider .sliderSponsors .sponsorButton.selected.sponsorClubElPaís {
  background-color: #1E509B;
  border-color: #1E509B;
}
.events.restaurantsSlider .sliderSponsors .sponsorButton.selected.sponsorBROU {
  background-color: #005CA9;
  border-color: #005CA9;
}

.events.restaurantsSlider .sliderSponsors button.selected {
  opacity: 1;
}

.events.restaurantsSlider .sliderSponsors button img {
  object-fit: cover;
  width: 100%;
  padding: 0;
  height: 40px;
  border-radius: 10px;
}

.viewMore {
  font-weight: 400;
  font-size: 17px;
  line-height: 23px;
  color: white;
  text-decoration: none;
  transition: 0.5s all ease-in-out;
  border: 0.5px solid transparent;
  border-radius: 10px;
  padding: 5px 10px;
}

.viewMore:hover {
  border: 0.5px solid white;
  color: white !important;
}

.slick-slide {
  height: auto !important;
}

.container-event-type-view .slick-slide {
  max-width: 375px;
}

.sub-container-events-recommended .slick-slide {
  max-width: 450px;
}

.arrowSliderRight,
.arrowSliderLeft {
  position: absolute;
  z-index: 1;
  top: 0;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  visibility: hidden;
  transition: .5s all ease-in-out;
  background: rgba(22, 21, 30, 0.78);
  cursor: pointer;
  border: unset;
  opacity: 0;
}

.background-img-home>.slick-slider {
  height: calc(100vh - 95px);
}

.slick-slider:hover .arrowSliderRight,
.slick-slider:hover .arrowSliderLeft {
  visibility: visible;
  opacity: 1;
}

.arrowSliderRight img,
.arrowSliderLeft img {
  height: 26px;
}

.arrowSliderLeft {
  left: 25px;
}

.arrowSliderRight {
  right: 0;
}


.Index .sliderEvents.noSlider {
  width: 96%;
  margin: auto;
}

.Index .sliderEvents.noSlider a {
  width: 22%;
  margin-right: 25px;
}

.Index .sliderEvents.noSlider a .event {
  width: 100%;
}

.Index .sliderEvents.noSlider {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

/* Ipads */
/*
@media only screen and (min-width: 820px) and (max-width: 1300px) {
  .background-hide {
    display: none;
  }
}
*/

/* Pantalla chica desktop */
@media only screen and (min-width: 1000px) and (max-width: 1300px) {
  .Index .sliderEvents.noSlider a {
    width: 28%;
    margin-right: 25px;
  }
}

/* Mobile */
@media only screen and (max-width: 700px) {

  .Index .sliderEvents.noSlider {
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .Index .sliderEvents.noSlider a {
    width: 90%;
    margin: auto;
  }

  .Index .sliderEvents.noSlider a .event {
    margin-bottom: 20px;
    margin-left: 0;
  }

  .Index .sliderEvents.noSlider a:last-child .event {
    margin-bottom: 0;
  }

  .titleTypeEvent,
  .subTitleTypeEvent {
    font-size: 21px;
  }

  .background-img-home .slick-slider {
    height: auto;
  }

  .lineTypeEvent {
    height: 2vh;
    width: 1px;
  }

  .containerTitleTypeEvent img {
    height: 40px;
    width: 40px;
  }

  .viewMore {
    font-size: 15px;
    border: 0.5px solid white;
    font-family: 'Avenir Book' !important;
    display: none;
  }

  .containerTitleTypeEvent {
    margin: auto;
    width: 90%;
    flex-direction: column;
    align-items: baseline;
  }

  .containerEvents {
    margin: auto;
    width: 100%;
  }

  .sliderSponsors:last-child {
    margin-top: 15px;
  }

  .background-hide {
    height: 95%;
  }

  .events.restaurantsSlider .sliderSponsors {
    width: 100%;
    overflow: auto;
  }
}


/* Mobile Pequeño */
@media only screen and (max-width: 375px) {

  .titleTypeEvent,
  .subTitleTypeEvent {
    font-size: 15px;
  }

  .viewMore {
    font-size: 12px;
  }
}