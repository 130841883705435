.container-frequent-questions {
    width: 80%;
    margin: auto;
    z-index: 100;
    position: relative;
}

.container-frequent-questions h2 {
    font-weight: 800;
    font-size: 25px;
    line-height: 34px;
    color: #FFFFFF;
    opacity: 0.7;
    margin-top: 50px;
}

.title-frequent-questions {
    font-weight: 800;
    font-size: 30px;
    line-height: 41px;
    color: #FFFFFF;
    margin-bottom: 30px;
    margin-top: 50px;
}

.question:nth-of-type(5),
.question:nth-of-type(10) {
    border-bottom: 1px solid white;
}


/* Mobile */
@media only screen and (max-width: 700px) {
    .container-frequent-questions {
        width: 85%;
    }

    .container-frequent-questions h2 {
        font-size: 20px;
    }

    .titleQuestion {
        text-align: initial;
    }

    .frequent-questions .blur-green {
        display: none;
    }

    .frequent-questions .blur-blue {
        bottom: 10px;
    }

    .numberQuestion {
        font-size: 10px;
        line-height: 14px;
    }
}