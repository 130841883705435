.container-form {
    height: calc(100vh - 70px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-image: url("/src/img/background-form.png");
    background-size: cover;
}

.container-form h1 {
    font-weight: 800;
    font-size: 35px;
    line-height: 40px;
    color: #FFFFFF;
}

.container-form h2 {
    color: #47FFBD;
    font-size: 35px;
    line-height: 40px;
    font-style: italic;
}

.container-form form {
    width: 45%;
    margin: 40px auto 0;
}

.container-swtich-form {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.sub-container-inputs {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;
}

.container-inputs {
    display: flex;
    flex-direction: column;
    width: 48%;
}

.container-inputs span {
    margin-top: 5px;
    margin-bottom: 20px !important;
    color: #47FFBD;
    font-size: 12px;
}

.container-inputs textarea {
    height: 147px;
    padding-top: 10px;
    padding-left: 10px;
    resize: none;
}

.container-inputs input {
    height: 32px;
    padding-left: 10px;
    font-size: 16px;
}

.container-inputs textarea,
.container-inputs input {
    border: transparent;
    border-bottom: 1px solid white;
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(27px);
    border-radius: 0;
    color: white;
    font-size: 16px;
}

.container-inputs input:focus,
.container-inputs textarea:focus {
    outline: none !important;
    border-bottom: 1px solid #47FFBD;
    ;
}

.button-form {
    float: right;
    width: 230px;
    color: #16151E;
    padding: 5px 0;
    font-size: 16px;
    background: #47FFBD;
    border: 1px solid #47FFBD;
    border-radius: 3px;
    cursor: pointer;
    border: unset;
    transition: .5s all ease-in-out;
}

.button-form:hover {
    color: #47FFBD;
    background: transparent;
    border: 1px solid #47FFBD;
}

.button-form+.message{
    display: none;
}

.button-form-pop-up+.message{
    display: flex;
}

/* Mobile */
@media only screen and (max-width: 700px) {
    .container-form{
        text-align: center;
        height: auto;
        padding: 40px 0 80px;
    }
    .container-form form {
        width: 90%;
    }
    .sub-container-inputs{
        flex-direction: column;
    }
    .container-inputs{
        width: 100%;
    }
    .button-form{
        margin-top: 30px;
        float: none;  
    }
    .container-swtich-form{
        flex-wrap: wrap;
        justify-content: center;  
    }
}


/* Ipad Mini & Air */
@media only screen and (min-width: 767px) and (max-width: 820px) {
    .container-form form {
        width: 80%;
    }
  }

  /* Ipad Pro */
@media only screen and (min-width: 821px) and (max-width: 1628px) {
    .container-form form {
        width: 80%;
    }
}