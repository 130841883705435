.map {
    width: 100%;
    height: calc(100vh - 95px);
    position: relative;
}

.container-search-arrow {
    z-index: 10;
    position: absolute;
    top: 20px;
    left: 2%;
    display: flex;
    align-items: center;
}


.locationSearch .ant-select-selector {
    width: 100%;
    height: 32px;
    padding: 0 11px;
    border: none !important;
    background: transparent !important;
}

.locationSearch.ant-select {
    display: flex;
    align-items: center;
}

.locationSearch.ant-select-focused .ant-select-selector,
.locationSearch .ant-select-selector:focus,
.locationSearch .ant-select-selector:active,
.locationSearch.ant-select-open .ant-select-selector {
    border: none !important;
    box-shadow: none !important;
}

.locationSearch.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    color: white;
    font-size: 16px;
}

.locationSearch .ant-select-arrow {
    display: none;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
    margin-top: 4px;
}

.locationSearch.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.locationSearch .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    color: white;
}

.container-search-arrow button {
    background:rgba(22, 21, 30, 0.4);
    border: none;
    -webkit-backdrop-filter: blur(75px);
    backdrop-filter: blur(75px);
    height: 40px;
    width: 40px;
    border-radius: 100px;
    margin-right: 10px;
    cursor: pointer;
}

.search-map {
    background: url("/src/img/search.svg"), rgba(22, 21, 30, 0.4);
    -webkit-backdrop-filter: blur(75px);
    backdrop-filter: blur(75px);
    border-radius: 130px;
    border: transparent;
    background-repeat: no-repeat;
    background-position-x: 93%;
    background-position-y: 50%;
    height: 40px;
    padding: 0 20px;
    width: 350px;
    cursor: pointer;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance: none !important;
    display: none !important;
}

.search-map:focus {
    color: white;
}

::placeholder {
    color: white;
}

.container-search-arrow button img {
    width: 50%;
    margin: auto;
}

.container-map header {
    background: #16151E;
}

.gm-svpc,
.gm-style-mtc {
    display: none;
}

.gm-control-active {
    display: none;
}

.gm-control-active {
    margin-bottom: 5px !important;
    background: white !important;
    transition: .5s all ease-in-out;
}

.gm-control-active:hover {
    background: #47FFBD !important;
}

.gmnoprint div {
    background: transparent !important;
}




/* Mobile */
@media only screen and (max-width: 700px) {
    .container-search-arrow {
        width: 95%;
    }

    .search-map {
        border-top-right-radius: 130px;
        border-bottom-right-radius: 130px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
    }

    .container-search-arrow button {
        margin: 0;
        border-top-left-radius: 130px;
        border-bottom-left-radius: 130px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
    }

    .container-map .headerDummy {
        height: 75px;
    }

    .map {
        height: calc(100vh - 75px);
    }

    .container-search-arrow{
        justify-content: center;
    }
    .search-map{
        width: 290px;
    }
}