.surveyPopup {
    display: flex;
    z-index: 999;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    position: absolute;
    background: rgba(0, 0, 0, 0.90);
    justify-content: center;
    align-items: center;
}

.surveyPopup .closeLogin {
    border: none;
    top: 1rem;
    right: 1rem;
}

.surveyPopup .closeLogin img {
    width: 0.7rem;
    filter: none;
}

.surveyPopup>div {
    position: relative;
    width: 75%;
    height: 75vh;
    padding: 1% 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 1.5rem;
    text-align: center;
    box-sizing: border-box;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.surveyPopup>div>div {
    transition: 0.5s ease;
}

.surveyPopup>div>div.hidden {
    opacity: 0;
}

.surveyPopup>div .firstSlide h1 {
    font-size: 2rem;
    width: 50%;
}

.surveyPopup>div .firstSlide h3 {
    font-size: 1rem;
    max-width: 65%;
}

.surveyPopup .flex {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    width: 100%;
}

@media only screen and (min-width: 700px) {

    .surveyPopup .flex.firstSlide {
        flex-direction: row;
        justify-content: center;
        gap: 0;
    }

    .surveyPopup .flex.firstSlide img {
        max-width: 50%;
        max-height: 100%;
        border-bottom-left-radius: 1.5rem;
    }

    .surveyPopup .flex.firstSlide .flex {
        width: auto;
    }

}

.surveyPopup button {
    cursor: pointer;
    transition: .5s all ease-in-out;
}

.surveyPopup .continueButton {
    background: #47FFBD;
    border: 1px solid #47FFBD;
    color: #16151E;
    border-radius: 5px;
    padding: 8px 2.5rem;
    font-weight: 900;
    font-size: 1rem;
    transition: 0.25s ease;
}

.surveyPopup .continueButton[disabled] {
    pointer-events: none;
    filter: grayscale(1);
}

.surveyPopup .continueButton:hover {
    /* background: transparent;
    color: #47FFBD;
    border: 1px solid #47FFBD; */
    transform: scale(0.95);
}

.surveyPopup .question {
    padding: 2.5rem 5%;
    border: none;
    border-radius: 1rem;
    backdrop-filter: blur(15px);
    background-color: rgba(255, 255, 255, 0.2);
    width: 90%;
}

.surveyPopup .rating {
    display: flex;
    justify-content: center;
    gap: 1rem;
    padding: 3rem 0;
    color: black;
}

.surveyPopup .rating button {
    position: relative;
    width: 10rem;
    aspect-ratio: 1;
    box-sizing: border-box;
    transition: 0.5s ease;
}

.surveyPopup .rating button img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    transition: 0.5s ease;
}

.surveyPopup .rating button img:nth-child(2) {
    opacity: 0;
}

.surveyPopup .rating button:hover {
    scale: 1.1;
}

.surveyPopup .rating button:hover img:nth-child(1),
.surveyPopup .rating button.selected img:nth-child(1) {
    opacity: 0;
}

.surveyPopup .rating button:hover img:nth-child(2),
.surveyPopup .rating button.selected img:nth-child(2) {
    opacity: 1;
}

.surveyPopup textarea {
    border-radius: 1rem;
    background-color: rgba(255, 255, 255, 0.2);
    border: none;
    resize: none;
    color: #545454;
    width: 100%;
    height: 10rem;
    margin: 1rem 0;
    padding: 1rem;
}

.surveyPopup textarea::placeholder {
    color: #545454;
}

.surveyPopup .options {
    display: grid;
    gap: 1rem;
    width: fit-content;
    min-width: 10rem;
    margin: 1rem auto;
}

.surveyPopup .options button {
    color: #A0A0A0;
    border: 1px solid #A0A0A0;
    border-radius: 5rem;
    text-align: left;
    padding: 0.5rem 1rem;
    transition: 0.5s ease;
}

.surveyPopup .options button:hover,
.surveyPopup .options button.selected {
    border-color: #47FFBD;
    background-color: #47FFBD;
    color: #16151E;
}

.surveyPopup input {
    width: 100%;
    border: none;
    border-bottom: #47FFBD solid 1px;
    background-color: rgba(255, 255, 255, 0.2);
    color: #545454;
    padding: 0.5rem 1rem;
}

.surveyPopup input::placeholder {
    color: #545454;
}

.surveyPopup form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    width: calc(100% / 3);
}

.surveyPopup form span {
    display: contents;
    margin-bottom: 1rem !important;
    font-size: 0.7rem;
    color: rgba(0, 0, 0, 0.85);
    line-height: 0;
}

/* Mobile */

@media only screen and (max-width: 700px) {

    .surveyPopup .flex.firstSlide {
        gap: 2.5rem;
    }

    .surveyPopup>div {
        width: 90vw;
        height: 95vh;
        padding: 0 1rem;
    }

    .surveyPopup>div .flex img {
        width: 75%;
        max-height: 50%;
        object-fit: contain;
    }

    .surveyPopup h1 {
        width: unset !important;
        font-size: 1.6rem !important;
    }

    .surveyPopup h2 {
        font-size: 1.4rem;
        line-height: normal;
    }

    .surveyPopup h3 {
        max-width: unset !important;
        font-size: 0.85rem !important;
    }

    .surveyPopup .rating {
        flex-wrap: wrap;
        margin: 2.5rem 0;
        padding: 0;
        gap: 0 1rem;
    }

    .surveyPopup .rating button {
        width: 29%;
    }

    .surveyPopup form {
        width: 90%;
    }

    .surveyPopup form span {
        line-height: normal;
    }
}