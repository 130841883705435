.orderBy {
    display: flex;
    float: right;
    justify-content: end;
    align-items: center;
    gap: 20px;
}

.orderBy p {
    color: white;
    font-size: 13px;
    margin-bottom: 0;
}

.MuiOutlinedInput-notchedOutline legend span {
    padding-right: 20px;
}


.inputLabel {
    color: white !important;
    font-size: 11px !important;
    line-height: 14px !important;
    top: 4px;
    font-family: "Avenir Book" !important;
}

.MuiSelect-select {
    color: white !important;
    font-size: 13px !important;
    line-height: 13px !important;
    display: flex;
    align-items: center;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8 {
    padding: 0 !important;
    border: 1px solid #47FFBD;
    border-radius: 8px;
    background: #16151E;
    margin-top: 10px;
}

.MuiInputBase-root .fieldset:hover {
    border-color: #47FFBD;
}

.MuiMenuItem-root.Mui-selected {
    background: transparent !important;
    color: #47FFBD;
}

/* Ipad */
@media only screen and (min-width: 768px) and (max-width: 820px) {
    .orderBy {
        width: 40%;
    }

    .orderBy .MuiFormControl-root {
        margin: auto 0;
    }

    .orderBy p {
        display: none;
    }
}


/* Mobile */
@media only screen and (max-width: 700px) {
    .container-recommended-filters-made {
        justify-content: flex-end !important;
    }

    .orderBy {
        z-index: 40;
        border: none;
    }

    .orderBy .MuiSelect-select {
        padding: 0 !important;
    }

    .orderBy .MuiFormControl-root {
        min-width: unset;
        margin: 0;
        flex-direction: row;
        align-items: center;
        gap: 5px;
    }

    .orderBy .MuiOutlinedInput-notchedOutline {
        border: none;
    }

    .orderBy .MuiSvgIcon-root {
        display: none;
    }
}

/* Mobile pequeño */
@media only screen and (max-width: 375px) {
    .orderBy {
        width: 45%;
    }
}