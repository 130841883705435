.ant-picker-dropdown {
    position: fixed;
    margin: 0px auto auto 0px;
    transform: translate(0px, 0px) !important;
    background: rgb(0, 0, 0, 0.5);
    height: 100%;
    width: 100%;
    top: 0 !important;
    display: flex;
    cursor: pointer;
}

.ant-space-vertical {
    cursor: pointer;
}

.ant-picker-dropdown-hidden {
    display: none;
}

.ant-picker-range {
    background: transparent !important;
    border: 1px solid #47FFBD !important;
    border-radius: 100px !important;
    height: 40px !important;
    cursor: pointer;
}

.ant-picker-input>input {
    color: white !important;
    font-family: "Avenir Book" !important;
    font-size: 13px !important;
    line-height: 6px !important;
    cursor: pointer;
}

.ant-picker-input>input::placeholder {
    text-transform: uppercase !important;
    /*color: #47FFBD !important;*/
    color: white !important;
}

.anticon svg {
    /*color: #47FFBD;*/
    color: white;
}


.ant-picker-clear:hover {
    background: #1E1E2A !important;
}

.ant-picker-clear {
    color: #1E1E2A !important;
}

.ant-picker-range-wrapper {
    width: 400px;
    margin: auto;
}

.ant-picker-range-arrow,
.ant-picker-super-next-icon,
.ant-picker-super-prev-icon {
    display: none !important;
}

.ant-picker-cell {
    color: rgba(109, 109, 109, 1) !important;
    font-family: "Avenir Book" !important;
    font-size: 13px !important;
    font-weight: 600 !important;
}

.ant-picker-header button,
.ant-picker-cell-in-view .ant-picker-cell-inner,
.ant-picker-content th {
    color: white;
    font-family: "Avenir Book" !important;
    font-size: 13px !important;
    font-weight: 600 !important;
    overflow: visible !important;
}

.ant-picker-header-view {
    pointer-events: none;
}

.ant-picker-content th,
.ant-picker-cell-disabled .ant-picker-cell-inner {
    color: #6D6D6D !important;
}

.ant-picker-range .ant-picker-active-bar {
    background: transparent !important;
}

.ant-picker-panel-container {
    background: rgba(22, 21, 30, 0.9) !important;
    border-radius: 20px !important;
}

.ant-picker-header,
.ant-picker-panel-container .ant-picker-panel {
    border-bottom: unset !important;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: 1px solid #47FFBD !important;
    border-radius: 3px !important;
}

.ant-picker-cell-range-hover-end::before {
    background-color: transparent !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
.ant-picker-cell-in-view:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-in-range::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-in-range .ant-picker-cell-inner,
.ant-picker-cell-in-view .ant-picker-cell-inner:hover {
    background: #47FFBD !important;
    color: #1E1E2A !important;
}

.ant-picker-cell-range-hover-start .ant-picker-cell-inner {}

.ant-picker-cell-range-hover::after,
.ant-picker-cell-range-hover-end::after,
.ant-picker-cell-range-hover-start::after {
    border: unset !important;
    background: #47FFBD !important;
}

.ant-picker-header>button:hover {
    color: #47FFBD !important;
}

.anticon-close-circle svg {
    color: #47FFBD !important;
    display: none;
}

.ant-picker-range:hover .ant-picker-clear {
    background: transparent !important;
}

.ant-picker-panel-container,
.ant-picker-panels,
.ant-picker-panel,
.ant-picker-date-panel,
.ant-picker-content {
    width: 100% !important;
}

.ant-picker-panels>*:first-child button.ant-picker-header-next-btn {
    visibility: visible !important;
}

.ant-picker-panels>*:first-child button.ant-picker-header-super-prev-btn,
.ant-picker-panels>*:first-child button.ant-picker-header-super-next-btn {
    display: none;
}

.ant-picker-panels>*:last-child {
    display: none;
}

.ant-picker-footer-extra>div {
    flex-wrap: wrap !important;
}

.ant-picker-input {
    width: auto;
}

.ant-picker .ant-picker-input span.ant-picker-suffix,
.ant-picker.ant-picker-range span.ant-picker-suffix {
    margin-left: 1px;
    margin-right: 10px;
    order: -1;
}
.ant-picker-range-separator{
    padding-right: 13px;
    padding-left: 8px;    
}
/*
.ant-picker-range-separator {
    position: absolute;
    right: 5px;
    height: 100%;
    display: flex;
    cursor: pointer;
}*/

.ant-picker-separator {
    content: url(../../img/arrow-map.svg);
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7454%) hue-rotate(50deg) brightness(112%) contrast(112%);;
    background-repeat: no-repeat;
    background-size: auto;
    height: 10px;
    width: 10px;
    background-position: center;
    transform: rotate(180deg);
    cursor: pointer;
}
/* Mobile  */
@media only screen and (max-width: 700px) {
    .ant-picker-panel-container .ant-picker-panels {
        flex-wrap: wrap;
        margin: auto;
    }

    .ant-picker-range-wrapper {
        width: 90%;
        margin: auto;
    }

    .ant-picker-panel-container .ant-picker-panels,
    .ant-picker-panel,
    .ant-picker-date-panel,
    .ant-picker-content {
        width: 100% !important;
    }
}