
.banner{
    width: 100%;
    display: flex;
    justify-content: center;
}
.bannerIntendencia .sub-container-banner{
    width: 100%;
}
.sub-container-banner{
    width: 90%;
}
.sub-container-banner img{
    width: 100%;
}

/* Mobile */
@media only screen  and (max-width: 490px) {
    .sub-container-banner{
        width: 90% !important;
    }
    .sub-container-banner img{
        height: auto;
    }
}