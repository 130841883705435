.map-restaurant-info {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 16px;
    width: 271px;
    height: fit-content;
    padding: 17px 17px 12px 16px;
    background: rgba(22, 21, 30, 0.4);
    backdrop-filter: blur(75px);
}

.map-restaurant-image-container {
    width: 100%;
    height: 192px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.map-restaurant-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.map-restaurant-close {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: #FFFFFF;
    position: absolute;
    top: 8px;
    right: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.map-restaurant-name {
    font-weight: 900;
    font-size: 21px;
    color: #FFFFFF;
}

.map-restaurant-address {
    font-weight: 400;
    font-size: 13px;
    color: #FFFFFF;
    margin-bottom: 10px;
}

.map-restaurant-extra-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.map-restaurant-distance {
    font-weight: 400;
    font-size: 11px;
    color: #FFFFFF;
    opacity: 0.5;
}

.map-restaurant-more-info {
    font-weight: 900;
    font-size: 11px;
    color: #47FFBD;
}

.restaurant-pin {
    cursor: pointer;
}