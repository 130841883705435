.privacy-policy{
    position: relative
}
.container-privacy-policy {
    width: 70%;
    margin: auto;
    padding: 90px 0;
}

.container-privacy-policy p:first-child{
    font-style: italic;
}

.container-privacy-policy h1,
.container-privacy-policy p,
.container-privacy-policy h2,
.container-privacy-policy h3,
.container-privacy-policy h4 {
    color: white;
}

.container-privacy-policy h1 {
    margin-top: 20px !important;
    margin-bottom: 10px !important;
}

.container-privacy-policy h2 {
    margin-bottom: 10px !important;
    margin-top: 0px !important;
}

.container-privacy-policy h3 {
    margin-bottom: 5px !important;
    margin-top: 0px !important;
}

.container-privacy-policy p {
    margin-bottom: 10px !important;
}
.blur{
    position: absolute;
}
.blur-purple{
    right: 0;
    width: 20%;
}
.blur-green{
    bottom: 0;
}

/* Mobile */
@media only screen and (max-width: 700px) {
    .container-privacy-policy{
        width: 85%;
        padding: 40px 0;
    }
    .blur-green {
        top: 500px;
    }
    .privacy-policy .blur-purple{
        top: 10px;
    }
}