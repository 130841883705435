.favoriteEvents {
    margin-top: 3rem;
    margin-left: 5rem;
    margin-right: 5rem;
}

tbody {
    color: black !important;
}

.eventsGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    padding: 2rem;
}

.eventSlider {
    text-decoration: none;
    color: inherit;
}

.event {
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.2s ease-in-out;
}

.event:hover {
    transform: scale(1.05);
}

.imgEvent {
    position: relative;
    height: 200px;
    overflow: hidden;
}

.imgEvent img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.infoEvent {
    padding: 1rem;
}

.titleEvent {
    font-size: 1.5rem;
    margin: 0;
    padding: 0;
}

.locationEvent {
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
}

.voyEventButton {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    background-color: #47ffbd;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    text-decoration: none;
    color: black;
}

.voyEventButton img {
    margin-left: 0.5rem;
}


/* Profile */

.profileBackground {
    background-image: url('../../img/backgroundProfile.png'); /* Ajusta la ruta si es necesario */
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    width: 100%;
    min-height: 100vh;
    padding: 50px 0;
}

.userProfileContainer {
    max-width: 800px;
    margin: auto;
    text-align: center;
}

.profileTitle {
    font-size: 2rem;
    margin-bottom: 20px;
    color: white;
}

.profileSections {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.profileCard {
    background: rgba(22, 21, 30, 0.9);
    padding: 20px;
    border-radius: 10px;
    width: 48%;
}

.profileCard h3 {
    color: white;
}

.profileInput {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: none;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.2);
    color: white;
}

.profileSaveButton {
    width: 100%;
    padding: 10px;
    background: #47FFBD;
    color: black;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
}

.interestsCard {
    background: rgba(22, 21, 30, .9);
    padding: 20px;
    border-radius: 10px;
    margin-top: 30px;
}

.interestsCard h3{
    color: white;
}

.interestsGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    gap: 10px;
    margin-bottom: 20px;
    margin-top: 20px;
}

.interestButton {
    background: black;
    border: 1px solid white;
    color: white;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
}

.interestButton.selected {
    background: #47FFBD !important;
    color: black !important;
    font-weight: bold;
    border: none !important;
}

@media (max-width: 768px) {
    .profileSections {
        flex-direction: column;
    }
    .profileCard {
        width: 100%;
    }
}
