header {
  position: fixed;
  z-index: 20;
  top: 0;
  height: 95px;
  display: flex;
  background-color: #16151E !important;
  z-index: 200;
  width: 100%;
  filter: drop-shadow(0px 4px 21px rgba(0, 0, 0, 0.25));
}

.noScroll {
  overflow: hidden;
}

.background-escondido {
  width: 100%;
  height: 95px;
  background: #16151E;
  position: absolute;
  z-index: -1;
}

.headerDummy {
  height: 95px;
  position: relative;
  width: 100%;
}

.logo {
  width: 90px;
}

.container-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: auto;

}

.sub-container-header {
  display: flex;
  align-items: center;
}

.start,
.hamburger {
  display: none !important;
}

/* Search */

.search {
  position: absolute;
  top: calc(50vh - 70px);
  height: 70px;
  width: 50vw;
  margin: 0 25vw;
  font-size: 1.2rem;
  border-radius: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  box-shadow: 0px 2.62699px 4.59723px rgb(0 0 0 / 5%);
  border: transparent;
  color: white;
  padding: 0 20px;
  box-sizing: border-box;
  text-indent: 30px;
  z-index: 100;
  background-color: rgba(255, 255, 255, 0.25);
  background-repeat: no-repeat;
  background-position: 20px;
  background-image: url("/src/img/search.svg");
  border: solid thin white;
  border-radius: 0.5rem;
  transition: 0.75s ease;
  filter: blur(7.5px);
}

.container-input-search.open .search {
  width: 70vw;
  margin: 0 15vw;
  filter: blur(0);
}

.container-input-search.open .search.showingResults {
  top: 25px;
  margin: 0 25px;
  width: calc(100% - 50px);
}

.searchResults .noResults {
  /* display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100% - 70px);
  text-align: center; */
  padding: 25px;
  font-size: 18px;
}

.searchResults .container-event-type-view .slick-slide {
  max-width: 315px;
}

.searchResults .container-event-type-view .slick-slide .infoEvent {
  height: 150px;
}

.searchResults .container-event-type-view .slick-slide .voyEventButton {
  display: none;
}

.searchResults .containerEvents {
  padding: 0.5em 0;
}

.searchResults .containerTitleTypeEvent {
  width: calc(100% - 25px);
  padding-bottom: 1em;
}

.searchResults .titleTypeEvent {
  font-size: 18px;
}

.searchResults .container-event-type-view .slick-slide .infoEvent {
  height: auto;
}

.searchResults .typeEvent {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.searchResults .titleEvent {
  font-size: 16px;
  line-height: 18px;
  margin-top: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.searchResults .hourEvent,
.searchResults .locationEvent {
  display: none !important;
}

.container-input-search {
  transition: 0.6s ease;
  position: fixed;
  z-index: 100;
  height: 100%;
  width: 100%;
  backdrop-filter: blur(10px);
  background: rgba(0, 0, 0, 0.6);
  top: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
}

.container-input-search .infoEvent {
  flex-direction: row !important;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
}

.container-input-search span {
  float: left;
}

.container-input-search .infoEvent p {
  display: none;
}

.container-input-search p:not(:first-child) {
  border-top: thin rgba(255, 255, 255, 0.6) solid;
}

/* Estilos eventos en busqueda de header */
.container-input-search .searchResults {
  /* background: rgb(39 39 39 / 75%);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px); */
  max-height: calc(100% - 70px - 2rem);
  margin: 0 1rem;
  margin-top: calc(70px + 2rem);
  overflow-x: hidden;
  width: calc(100% - 1rem);
  border-radius: 10px;
}

.searchResults .sliderEvents {
  width: 100%;
}

.searchResults .arrowSliderLeft {
  left: 0;
}

.subContainer-searchResults {
  width: 100% !important;
  margin: auto;
  overflow: hidden;
}

.subContainer-searchResults>div {
  /* height: calc(33vh - 70px - 2.5vw); */
}

.subContainer-searchResults .event.eventButton {
  width: calc(25% - 5px);
}

.searchResults::-webkit-scrollbar {
  width: 10px;
}

.searchResults::-webkit-scrollbar-thumb {
  background-color: #47FFBD;
  border-radius: 10px;
}

.container-input-search.open {
  visibility: visible;
  opacity: 1;
  z-index: 1000;
}

.searchMobileHeader.searchMobileOpen,
.container-input-search input:focus-visible {
  color: white;
  outline-width: 0;
}

.closeSearchButton {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 47.5px;
  right: 47.5px;
  filter: invert();
  cursor: pointer;
  transition: 1s ease;
  z-index: 1000;
}

.closeSearchButton.showingResults {
  visibility: visible;
  opacity: 1;
}

.closeSearchButton img {
  width: 25px;
  height: 25px;
}

.searchMobileHeader {
  background: transparent;
  border: unset;
  width: 20px;
  height: 20px;
}

.searchMobileHeader img {
  width: 100%;
  cursor: pointer;
  transition: .5s all ease-in-out;
}

.searchHeaderIcon {
  margin-top: 4px;
}

.search::placeholder {
  color: white;
}

.search:focus {
  color: white;
}

.user {
  display: flex;
  transition: .5s all ease-in-out;
  background-color: transparent;
  border: transparent;
  cursor: pointer;
  margin-top: 5px;
  margin-left: 20px;
}

.start {
  width: 25px;
  height: 25px;
  display: flex;
  transition: .5s all ease-in-out;
  margin: 0 15px;
}

.user:hover,
.start:hover,
.searchMobileHeader img:hover {
  filter: brightness(0) saturate(100%) invert(96%) sepia(13%) saturate(2071%) hue-rotate(80deg) brightness(99%) contrast(103%);
}

.start img,
x .user img {
  cursor: pointer;
  width: 100%;
}


/* Mobile */
@media only screen and (max-width: 700px) {

  .search {
    height: 60px;
  }

  .container-input-search.open .search {
    width: 85vw;
    margin: 0 7.5vw;
  }

  .container-input-search .searchResults {
    margin-top: calc(60px + 2rem);
  }

  .searchResults .sliderEvents .slick-slider .slick-list {
    margin-left: 15px;
  }

  .searchResults .sliderEvents {
    width: 100%;
    margin-right: 0;
  }

  .container-input-search {
    height: 100%;
  }

  .searchResults .container-event-type-view .slick-slide {
    max-width: 300px;
  }

  .closeSearchButton {
    top: 45px;
    right: 45px;
  }

  .closeSearchButton img {
    width: 20px;
    height: 20px;
  }

  /* .subContainer-searchResults {
    width: 90% !important;
    margin: auto;
  } */

  /* .subContainer-searchResults .event {
    margin-right: auto !important;
    margin-bottom: 20px;
    margin-left: auto !important;
  } */

  .subContainer-searchResults .event.eventButton {
    width: 100%;
  }

  header,
  .background-escondido {
    top: 0;
    height: 75px;
    background-color: #1E1E2A;
  }

  .container-header {
    width: 90%;
  }

  .giftMenu {
    margin-right: 0;
  }

  .hamburger .container-hamburger {
    background: none;
    border: 0;
    cursor: pointer;
    padding: 0;
    width: 32px;
    height: 32px;
    margin-left: 10px;
  }

  .hamburger .container-hamburger span {
    background-color: white;
    display: block;
    height: 3px;
    border-radius: 100px;
    width: 100%;
    margin: 6px auto;
    animation: 1s;
    animation-fill-mode: forwards;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
  }

  .middle-line {
    margin: 6px 0px 0px auto !important;
    width: 80% !important;
  }

  .bottom-line {
    margin: 6px 0px 0px auto !important;
    width: 60% !important;
  }

}

/* Ipad Air & Mini */
@media only screen and (min-width: 767px) and (max-width: 820px) {
  .logo {
    width: 75px;
  }
}

/* Ipad Pro */
@media only screen and (min-width: 821px) and (max-width: 1300px) {
  .container-header {
    width: 95%;
  }

  .gift {
    height: 20px;
    width: 20px;
    object-fit: contain;
  }

  .searchMobileHeader,
  .start,
  .user {
    height: 20px;
    width: 20px;
  }
}