.category-title {
    font-size: 16px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    justify-content: space-between;
    /* width: 95%; */
    /* margin-bottom: 20px !important;
    margin-top: 10px !important;*/
}

span.category-title,
span.textDistance {
    padding: 0 calc(16px - 5%);
    line-height: 64px;
}

.limpiarFiltros {
    width: 92%;
    margin-left: auto;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    padding-bottom: 20px;
}

.limpiarFiltros button {
    border: transparent;
    padding: 0;
    margin: 5px 0;
    color: #47FFBD;
    font-weight: 900;
    font-size: 14px !important;
    height: auto;
    cursor: pointer;
}

.limpiarFiltros button:hover {
    color: white !important;
    background-color: unset;
    padding: unset;
    border: unset;
}

.container-type-event {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.container-type-event .button {
    margin-bottom: 10px !important;
    margin-left: 0px !important;
    margin-right: 10px !important;
}

/*
.container-type-event .ant-select-single .ant-select-selector .ant-select-selection-search{
    margin-top: 6px;
}*/

.container-type-event .button-click {
    margin-bottom: 10px !important;
    margin-left: 0px !important;
    margin-right: 10px !important;
}


#date-filters {
    background: transparent;
    border: 1px solid white;
    border-radius: 100px;
    color: white;
    width: calc(100% - 40px);
    padding: 10px 20px;
    text-transform: uppercase;
    cursor: pointer;
}

.button-where {
    width: 100%;
    border-radius: 6px;
    background: url('/src/img/backgroundFilter-Where.png');
    background-size: cover;
    background-position: top;
    display: flex;
    padding: 15px 0;
    justify-content: space-evenly;
    margin-bottom: 10px !important;
}

.search-filters {
    background: url('/src/img/search.svg'), transparent;
    background-repeat: no-repeat;
    background-position-x: 95%;
    background-position-y: 50%;
    width: 100%;
    border-radius: 100px;
    border: 1px solid white;
    padding: 0 10px;
    height: 40px;
    font-size: 13px;
    color: white;
    line-height: 12px;
    margin-bottom: 10px !important;
}

.search-filters.selected {
    border-color: #47FFBD;
    background: #47FFBD;
}

.search-filters.selected .ant-select-selection-item {
    color: #16151E !important;
    padding-right: 12.5%;
}

.search-filters.selected .ant-select-selection-item img {
    display: block;
    position: absolute;
    right: 0;
    height: 70%;
    top: 15%;
    width: 10%;
    filter: brightness(0);
}

.button-where+span {
    color: white;
    padding: 20px 0;
}

.button-where+span+div .button,
.button-where+span+div .button-click {
    width: 49%;
    margin-right: 0 !important;
    padding: 10px 3px;
}

.textDistance {
    color: white !important;
}

.container-type-event .ant-picker-range-separator {
    padding-right: 8px !important;
    padding-left: 0 !important;
}

.viewMap-filters {
    border-radius: 100px;
    padding: 0 20px;
    font-size: 13px;
    font-weight: bold;
    height: 40px;
    cursor: pointer;
    background-color: #47FFBD !important;
    border: 1px solid #47FFBD !important;
    color: #434249 !important;
}

/* Contenedor filtros */
.container-filters {
    width: 19%;
}

.filters {
    width: 100%;
}

.background-filters {
    background: linear-gradient(181.15deg, rgba(232, 232, 232, 0.30) 0%, rgba(226, 226, 226, 0.20) 60%);
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(8px);
    border-radius: 6px;
    margin: 0px 0px 20px;
    width: 100%;
    z-index: 5;
}


.title-filters {
    font-weight: 800;
    font-size: 20px;
    line-height: 20px;
    color: #FFFFFF;
    margin: 0;
    min-height: 48px;
    display: flex;
    align-items: center;
    padding-left: 25px;
    width: calc(100% - 25px);
    transition: 0.5s ease;
}

.filter {
    border: transparent !important;
}

.filters .MuiPaper-root .MuiButtonBase-root .MuiAccordionSummary-content {
    margin: 0;
}

.MuiAccordionSummary-expandIconWrapper {
    color: white;
}

.css-15v22id-MuiAccordionDetails-root {
    padding: 0;
}

.filters .MuiPaper-root .MuiCollapse-root .css-smkl36-MuiCollapse-wrapper {
    width: 90%;
    margin: auto;
    padding-bottom: 1rem;
}

.container-type-event .ant-space-vertical {
    width: 100% !important;
}

.container-type-event .ant-picker-range {
    border: 1px solid white !important;
    width: 100% !important;
    height: 40px;
    margin-top: 7px !important;
    justify-content: space-around;
}

.container-type-event .MuiFormControl-root .MuiInputLabel-root {
    font-size: 13px;
    line-height: 13px;
}

.container-type-event .MuiFormControl-root .MuiOutlinedInput-root .MuiInputAdornment-root .MuiButtonBase-root {
    border: unset;
    padding: 0;
    margin-right: 5px;
}

.container-type-event .MuiFormControl-root .MuiOutlinedInput-root .MuiInputAdornment-root .MuiButtonBase-root:hover {
    background-color: unset;
    color: white;
}


/* Mobile */
@media only screen and (max-width: 700px) {

    span.category-title,
    span.textDistance {
        padding: 0 calc(16px - 2.5%);
        line-height: 55px;
    }

    .filter {
        right: 100%;
        height: 50px;
        display: flex;
        overflow: hidden;
        transition: .5s all ease-in-out;
        justify-content: end;
        border-radius: 0;
        border-top-right-radius: 100px !important;
        border-bottom-right-radius: 100px !important;
        position: relative;
        top: 4px;
    }

    .background-filters:nth-of-type(1) {
        margin-bottom: 16px !important;
    }

    /*
    .container-bannerBank+header+.headerDummy+.container-events-view .sub-container-event-view .container-filters .background-filters:first-child*/
    /* .container-bannerBank+header+.headerDummy+.container-events-view .sub-container-event-view .container-filters .background-filters-open-mobile:first-child */
    /*{
        top: 249px;
    }
    /*
    .container-bannerBank+header+.headerDummy+.container-events-view .sub-container-event-view .container-filters .background-hide-mobile {
        height: 195px;
    }
    */

    .container-bannerBank+header+.headerDummy+.container-events-view .sub-container-event-view .container-filters .filters-mobile {
        top: 120px;
    }

    .title-filters {
        width: calc(100% - 25px);
    }

    .background-filters:nth-of-type(1) {
        margin-bottom: 0 !important;
    }

    .filter img {
        padding-right: 5px;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 20px;
        width: 66px;
    }

    .button-where+span+div {
        display: flex !important;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .limpiarFiltros {
        padding-bottom: 0;
    }

    .limpiarFiltros button {
        margin: 0;
    }

    .filters+.background-hide-mobile {
        display: none !important;
    }

    .filters-mobile+.background-hide-mobile {
        display: block !important;
    }

    .background-hide-mobile {
        position: fixed;
        height: 70px;
        width: 100%;
        background: #16151E;
        top: 57px;
        z-index: 10;
    }

    .when-filters,
    .what-filters,
    .where-filters,
    .price-filters,
    .promotions-filters,
    .highlights-filters {
        right: 100%;
        margin-top: 5% !important;
        transition: .5s all ease-in-out;
        display: block !important;
    }

    .container-filters-open-mobile {
        z-index: 10;
        width: 90%;
    }

    .background-filters {
        background: #3C3B42;
    }

    .background-filters-open-mobile {
        width: 90%;
        display: block !important;
        right: 0;
        position: relative;
        margin-left: 5% !important;
        margin-top: 5% !important;
        z-index: 10;
        border-radius: 5px;
        background: #3C3B42;
        z-index: 50;
    }

    .background-filters-open-mobile:nth-of-type(2),
    .background-filters:nth-of-type(2) {
        margin-top: 70px !important;
    }

    .container-type-event .MuiFormControl-root .MuiInputLabel-root {
        left: 0 !important;
    }

    .container-type-event .MuiFormControl-root .MuiOutlinedInput-root .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
        text-align: left !important;
        font-size: 13px;
    }

    .background-filters-open-mobile:first-child,
    .background-filters:first-child {
        margin-left: 0 !important;
        display: flex !important;
        align-items: center;
        margin-top: unset !important;
        position: fixed;
        top: 95px;
        z-index: 100;
    }

    .background-filters-open-mobile:first-child {
        width: calc(100% - 20px) !important;
        margin-right: 20px;
        margin-left: auto !important;
        box-shadow: 2px 21px 78px 9px rgba(0, 0, 0, 0.91);
        -webkit-box-shadow: 2px 21px 78px 9px rgba(0, 0, 0, 0.91);
        -moz-box-shadow: 2px 21px 78px 9px rgba(0, 0, 0, 0.91);
    }

    .filters-mobile {
        background: #16151E !important;
        transition: 0.5s ease-in;
        z-index: 20;
        position: fixed;
        height: 100vh;
        overflow: scroll;
        padding-bottom: 200px;
    }

    .css-smkl36-MuiCollapse-wrapper {
        width: 95% !important;
        margin: auto;
        padding-bottom: 20px;
    }

    .filters-mobile .MuiPaper-root .MuiAccordionSummary-gutters {
        height: 55px !important;
    }

    .checkboxFrom .MuiSwitch-switchBase {
        top: 0;
    }

    .clean-add-filters {
        transition: bottom 0.6s ease-in-out;
        position: fixed;
        z-index: 100;
        bottom: -100px;
        width: 100%;
    }

    .background-filters-open-mobile+.clean-add-filters {
        bottom: 0;
    }

    .button-limpiar,
    .button-aplicar {
        width: 50%;
        font-weight: 900;
        border: transparent;
        padding: 20px 0;
        font-size: 16px;
        box-shadow: 1px 30px 38px 46px rgba(0, 0, 0, 0.46);
        -webkit-box-shadow: 1px 30px 38px 46px rgba(0, 0, 0, 0.46);
        -moz-box-shadow: 1px 30px 38px 46px rgba(0, 0, 0, 0.46);
    }

    .button-limpiar {
        background: #1E1E2A;
        color: white;
        border-top-left-radius: 30px;
    }

    .button-aplicar {
        background: #47FFBD;
        color: #16151E;
        border-top-right-radius: 30px;
    }
}


/* Ipad Pro */
@media only screen and (min-width: 821px) and (max-width: 1200px) {
    .container-event-type-view {
        width: 70%;
    }
}