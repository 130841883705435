.star {
  background: rgba(255, 255, 255, 0.11);
  backdrop-filter: blur(10);
  border-radius: 10px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  border: unset;
  cursor: pointer;
  transition: .5s all ease-in-out;
  display: none;
}

.star .Música path {
  stroke: #2DEAF7;
}

.star .Música path:hover {
  fill: #2DEAF7;
}

.starSelected .Música path.background-star {
  fill: #2DEAF7;
}

.star .Teatro path {
  stroke: #EB7405;
}

.star .Teatro path:hover {
  fill: #EB7405;
}

.starSelected .Teatro path.background-star {
  fill: #EB7405;
}

.star .Cine path {
  stroke: #CF74F5;
}

.star .Cine path:hover {
  fill: #CF74F5;
}

.starSelected .Cine path.background-star {
  fill: #CF74F5;
}

/*
.container-information .typeEvent.Música .star svg{
  stroke: #47FFBD;
}

.container-information div .starSelected .background-star{
  fill: #47FFBD;
}
*/


.star svg {
  width: 80%;
}