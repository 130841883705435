
.containerClients {
   /* display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;*/
    margin: auto;
    padding: 4em 0 6em;
}

.containerClients .slick-slider .slick-list .slick-track{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.containerClients .slick-slider .slick-list .slick-track .slick-slide{
    margin: 0 10px;
}
.containerClients .slick-slider .slick-list .slick-track .slick-slide div img{
    width: 130px !important;
}
.containerClients .slick-slider .slick-list .slick-track .slick-slide div img{
    height: 60px;
}
.heightCLient{
    height: 30px !important;
}
.heightCLientItau{
    height: 40px !important;
}
.containerClients .slick-slider .slick-list .slick-track .slick-slide div{
    display: flex;
    justify-content: center;
}

/* Destkop pantalla chica */
@media only screen and (min-width: 1000px) and (max-width: 1400px) {
    .containerClients .slick-slider .slick-list .slick-track .slick-slide div img {
      width: 100px;
    }
    .containerClients .slick-slider .slick-list .slick-track .slick-slide div img:nth-of-type(3){
        height: 50px;
    }
}

/* Ipad */
@media only screen and (min-width: 767px) and (max-width: 820px) {
    .containerClients .slick-slider .slick-list .slick-track .slick-slide div img {
        width: 120px !important;
      }
      .containerClients .slick-slider .slick-list .slick-track .slick-slide div img{
          height: 40px;
      }
}

/* Mobile */
@media only screen  and (max-width: 700px) {
    .containerClients .slick-slider .slick-list .slick-track .slick-slide div img{
        max-width: 100px !important;
    }
    .containerClients{
        pointer-events: none;
    }
}


/* Mobile Pequeño */
@media only screen and (max-width: 375px) {
    .containerClients .slick-slider .slick-list .slick-track .slick-slide div img{
        max-width: 80px !important;
        height: 40px !important;
    }
  }
    