.gift {
  cursor: pointer;
  width: 70px;
  height: 70px;
  margin-bottom: 16px;
}

.navbar-open img {
  display: none;
}

.navbar-open .promotion {
  background-image: url("../../img/gift.png");
  background-size: 35px;
  background-repeat: no-repeat;
  background-position: 53% 51%;
  height: 70px;
}

.sponsor-container {
  height: fit-content;
}

.sponsors {
  display: block;
}

.sponsors-text {
  text-align: center;
  color: white;
  display: block;
  height: fit-content!important;
  margin-top: 5px;
}

.navbar__burguer-icon {
  background: transparent;
  border: transparent;
}


.navbar_link {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 10;
  right: -400px;
  width: 400px;
  transition: right 0.6s ease-in-out;
}

.navbar_link.open {
  right: 0;
}

.navbar-icon,
.navbar-open {
  background: transparent;
  border: transparent;
  margin: 0 15px;
  height: 90px;
  width: 70px;
}


.containerPromotions {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 35px;
  right: 0;
  bottom: 0;
  height: calc(100vh - 95px);
  background: rgba(22, 21, 30, 0.8);
  width: 100%;
}

/*
@keyframes menu-animation {
  0% {
    opacity: 1;
    transform: translateX(500px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }

}
*/

.logoPromotions {
  float: left;
  margin: 0 auto 0;
  padding-bottom: 20px;
  text-align: center;
  font-weight: 800;
  font-size: 30px;
  line-height: 30px;
  color: white;
}

.logoPromotions p {
  font-size: 16px;
  font-weight: normal;
}

.div-banks-logo {
  width: 80%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 46% 46%;
  /* grid-template-rows: 80px;  */
  align-items: center;
  gap: 20px 0;
  justify-content: space-between;
}

.div-banks-logo a {
  display: flex;
  flex-direction: column;
  gap: 5px;
  text-align: center;
  color: white;
}

.div-banks-logo a img {
  width: 100%;
  object-fit: cover;
  height: 80px;
  border-radius: 10px;
}

.buttonCloseArrow {
  background: transparent;
  border: transparent;
  cursor: pointer;
}

/* Pantalla chicas y medianas de desktop */
@media only screen and (min-width: 1000px) and (max-width: 1500px) {
  .div-banks-logo {
    width: 65%;
  }
}

@media only screen and (max-height: 800px) {
  .div-banks-logo {
    width: 65%;
  }
}


/* Mobile chico */
@media only screen and (max-width: 380px) {
  .logoPromotions {
    font-size: 25px;
  }

  .div-banks-logo {
    width: 50%;
  }
}


/* Mobile */
@media only screen and (max-width: 700px) {
  .navbar_link {
    width: 100%;
    right: -950px;
    top: 0;
  }

  .gift {
    cursor: pointer;
    width: 60px;
    height: 60px;
    margin-bottom: 13px;
    margin-left: 15px;
  }

  .promotion {
    margin-bottom: 5px;
    background-image: unset !important;
  }

  .containerPromotionsMobile {
    background: rgba(22, 21, 30, 1);
    display: flex !important;
    flex-direction: column;
    justify-content: flex-start !important;
    padding-top: 70px;
    right: 0;
    bottom: 0;
    width: 100%;
  }

  .logoPromotions {
    width: 100%;
    margin-bottom: 50px;
  }

  .close {
    display: none !important;
  }

  .buttonCloseArrow {
    display: none !important;
  }

  .containerPromotionsMobile .close-up,
  .containerPromotionsMobile .close {
    text-align: end;
    background: transparent;
    width: 35px;
    height: 35px;
    display: flex !important;
    border-radius: 100px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    left: 20px;
    top: 35px;
    transition: .5s all ease-in-out;
  }

  .containerPromotionsMobile .close-up img,
  .close img {
    filter: brightness(0) saturate(100%) invert(96%) sepia(13%) saturate(2071%) hue-rotate(80deg) brightness(99%) contrast(103%);
    width: 100%;
    height: 100%;
  }
}

/* Ipad Mini & Air */
@media only screen and (min-width: 767px) and (max-width: 820px) {
  .navbar_link {
    width: 370px;
  }

  .div-banks-logo {
    width: 70%;
  }
}

/* Ipad Pro */
@media only screen and (min-width: 821px) and (max-width: 1628px) {
  .navbar_link {
    z-index: 1;
  }
}