
.resultsFilters {
    display: flex;
    align-items: center;
    flex-flow: wrap;
    margin: 10px 0;
}

.results {
    color: white;
    margin: 0 10px;
}

.result {
    opacity: 0.6;
}

.resultsFilters img {
    height: 15px;
}

.resultsFilters button {
    cursor: pointer;
}

.resultsFilters button img {
    height: 20px;
}

.container-recommended-filters-made {
    display: flex;
    align-items: center;
    width: 90%;
    margin: 0px auto 50px;
    justify-content: space-between;
    position: relative;
}

/* Mobile */
@media only screen and (max-width: 700px) {
    .container-recommended-filters-made {
        display: flex;
        align-items: flex-end;
        width: 90%;
        flex-direction: column-reverse;
        gap: 25px;
    }
}

/* Ipad */
@media only screen and (min-width: 768px) and (max-width: 820px) {
    .results {
        font-size: 12px;
    }
}