#loader {
    opacity: 0;
    visibility: hidden;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.25);
    backdrop-filter: blur(2.5px);
    z-index: 1000;
    transition: ease 0.75s;
}

#loader.show {
    visibility: visible;
    opacity: 1;
}

#loader img {
    width: 100px;
}