.map-container {
    position: relative;
    width: 100%;
    height: 100%;
}

.map-where-go .map-container>div>div,
.map-where-go .map-container>div>div>div {
    border-radius: 7.5px;
}

.gmnoprint {
    right: unset !important;
    left: 1% !important;
    top: 33vh !important;
}