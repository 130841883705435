.container-form {
    height: auto !important;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-image: url("/src/img/background-form.png");
    background-size: cover;
    padding: 40px 0 100px 0;
}

.container-form .giveawayImage {
    width: 100%;
    margin-bottom: 50px;
}

.container-form form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.container-form h1 {
    font-weight: 800;
    font-size: 35px;
    line-height: 40px;
    color: #FFFFFF;
}

.container-form h2 {
    color: #47FFBD;
    font-size: 35px;
    line-height: 40px;
    font-style: normal !important;
    font-family: 'Avenir Book' !important;
}

.container-form form {
    width: 45%;
    margin: 40px auto 0;
}

.container-swtich-form {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.sub-container-inputs-draw {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;
}

.container-inputs-draw {
    display: flex;
    flex-direction: column;
    /* width: 96%; */
    flex-flow: wrap;
    justify-content: space-around;
}

.container-inputs-draw>div>span {
    margin-top: 10px;
    margin-bottom: 20px !important;
    color: #47FFBD;
    font-size: 12px;
}

.container-inputs-draw textarea {
    height: 147px;
    padding-top: 10px;
    padding-left: 10px;
    resize: none;
}

.container-inputs-draw input {
    height: 32px;
    padding-left: 10px;
    font-size: 16px;
}

.container-inputs-draw>div {
    display: flex;
    flex-direction: column;
    width: 48%;
}

.container-inputs-draw div input {
    width: 100%;
}

.container-inputs-draw textarea,
.container-inputs-draw input {
    border: transparent;
    border-bottom: 1px solid white;
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(27px);
    border-radius: 0;
    color: white;
    font-size: 16px;
}

.container-inputs-draw input:focus,
.container-inputs-draw textarea:focus {
    outline: none !important;
    border-bottom: 1px solid #47FFBD;
}

.button-form {
    float: right;
    width: 230px;
    color: #16151E;
    padding: 5px 0;
    font-size: 16px;
    background: #47FFBD;
    border: 1px solid #47FFBD;
    border-radius: 3px;
    cursor: pointer;
    border: unset;
    font-weight: bold;
    transition: .5s all ease-in-out;
}

.button-form:hover {
    color: #47FFBD;
    background: transparent;
    border: 1px solid #47FFBD;
}

.button-form+.message {
    display: none;
}

.button-form-pop-up+.message {
    display: flex;
}

.vosvasyovoy {
    margin: 50px 0;
    max-width: 75%;
}

/* Mobile */
@media only screen and (max-width: 700px) {
    .container-form {
        text-align: center;
        height: auto;
        padding: 40px 0 80px;
    }

    .container-form form {
        width: 90%;
    }

    .container-inputs-draw div input {
        height: 40px;
    }

    .container-inputs-draw>div,
    .container-inputs-draw div input {
        width: 100%;
    }

    .sub-container-inputs {
        flex-direction: column;
    }

    .container-inputs-draw {
        width: 100%;
    }

    .button-form {
        margin-top: 0 !important;
        float: none;
        width: 100% !important;
        height: 40px;
    }

    .container-swtich-form {
        flex-wrap: wrap;
        justify-content: center;
    }
}


/* Ipad Mini & Air */
@media only screen and (min-width: 767px) and (max-width: 820px) {
    .container-form form {
        width: 80%;
    }
}

/* Ipad Pro */
@media only screen and (min-width: 821px) and (max-width: 1628px) {
    .container-form form {
        width: 80%;
    }
}

/* Giveaways */

.container-form .selectable-giveaways {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 25px 0 50px 0;
    width: 80%;
}

.container-form a {
    color: white;
}

.container-form .selectable-giveaways .selectable-giveaway {
    width: calc(33.3% - 25px);
    height: 100%;
    margin: 25px 12.5px;
    background: #1e1e2a;
    transition: 0.5s ease;
    cursor: pointer;
    border-radius: 10px;
    position: relative;
}

.container-form .selectable-giveaways .selectable-giveaway .selectable-giveaway-background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(180deg, #1e1e2a, #66ffbf);
    opacity: 0;
    transition: 0.5s ease;
    border-radius: 10px;
}

/* .container-form .selectable-giveaways .selectable-giveaway.selected .selectable-giveaway-background {
    opacity: 1;
} */

.container-form .selectable-giveaways .giveawayImage {
    margin-bottom: 0;
    border-radius: 10px 10px 0 0;
    transition: 0.5s ease;
    position: relative;
    aspect-ratio: 16 / 9;
    object-fit: cover;
}

.container-form .selectable-giveaways .selectable-giveaway .selectable-giveaway-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 15px;
    width: 100%;
    padding: 25px;
    position: relative;
}

.container-form .selectable-giveaways .selectable-giveaway .selectable-giveaway-info>h4,
.container-form .selectable-giveaways .selectable-giveaway .selectable-giveaway-info>span,
.container-form .selectable-giveaways .selectable-giveaway .selectable-giveaway-info>p {
    font-weight: bold;
    text-align: left;
}

.container-form .selectable-giveaways .selectable-giveaway .selectable-giveaway-info>h4 {
    font-size: 22px;
    font-weight: bold;
    color: white;
}

.container-form .selectable-giveaways .selectable-giveaway .selectable-giveaway-info>span {
    font-size: 12px;
    color: #66ffbf;
    text-transform: uppercase;
    text-align: left;
    letter-spacing: 3.75px;
}

.container-form .selectable-giveaways .selectable-giveaway .selectable-giveaway-info>p {
    text-align: left;
    display: flex;
    gap: 5px;
}

.container-form .selectable-giveaways .selectable-giveaway .selectable-giveaway-info .voyEventButton {
    height: 45px;
    font-size: 15px !important;
    color: white;
}

.container-form .selectable-giveaways .selectable-giveaway .selectable-giveaway-info .voyEventButton:hover {
    color: white;
}

.container-form .selectable-giveaways .selectable-giveaway.selected .selectable-giveaway-info .voyEventButton {
    background: #66ffbf;
    color: #1e1e2a;
}

.container-form .selectable-giveaways .selectable-giveaway .selectable-giveaway-info .voyEventButton img {
    max-height: 30px;
}

.container-form .selectable-giveaways .selectable-giveaway:not(.selected) .selectable-giveaway-info .voyEventButton span.selected {
    display: none;
}

.container-form .selectable-giveaways .selectable-giveaway.selected .selectable-giveaway-info .voyEventButton span:not(.selected) {
    display: none;
}

.container-form .selectable-giveaways .selectable-giveaway:not(.selected) .selectable-giveaway-info .voyEventButton img.selected {
    display: none;
}

.container-form .selectable-giveaways .selectable-giveaway.selected .selectable-giveaway-info .voyEventButton img:not(.selected) {
    display: none;
}

/* .container-form .selectable-giveaways .selectable-giveaway .selectable-giveaway-info .voyEventButton img {
    transition: 0.5 ease;
    filter: grayscale(1)
}

.container-form .selectable-giveaways .selectable-giveaway.selected .selectable-giveaway-info .voyEventButton img {
    filter: none;
} */

.container-form .continueTooltip {
    position: fixed;
    bottom: 2.5%;
    padding: 15px 120px;
    max-width: 90%;
    background: #66ffbf;
    color: #1e1e2a;
    border-radius: 10px;
    font-weight: bold;
    font-family: 'Avenir Book';
    font-size: 16px;
    text-transform: uppercase;
    z-index: 10;
    /* box-shadow: 0px 15px 30px 40px rgb(0 0 0 / 50%); */
}

.container-form .continueTooltip.hidden {
    display: none;
}

/* Mobile */
@media only screen and (max-width: 700px) {
    .container-form .selectable-giveaways {
        display: flex;
        flex-wrap: wrap;
        margin-top: 25px;
        width: 95%;
    }

    .container-form .selectable-giveaways .selectable-giveaway {
        width: calc(100% - 25px);
    }

    .container-form .continueTooltip {
        width: 80%;
        padding: 15px 0;
    }
}