.close-location-info {
    width: 28px;
    height: 28px;
    border: 1px solid #47FFBD;
    border-radius: 50%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 20px;
    top: 20px;
    cursor: pointer;
}

.close-location-info img {
    filter: brightness(0) saturate(100%) invert(96%) sepia(13%) saturate(2071%) hue-rotate(80deg) brightness(99%) contrast(103%);
}

.location-marker .location-info-container {
    right: -100%;
}

.map-container .location-info-container {
    right: -100%;
}

.location-info-container {
    position: absolute;
    top: 0;
    width: 440px;
    height: calc(100vh - 95px);
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 80px 0 20px;
    background-color: rgba(22, 21, 30, 0.4);
    -webkit-backdrop-filter: blur(75px);
    backdrop-filter: blur(75px);
    color: white;
    transition: 1s ease-in-out;
    right: 0 !important;
}

.location-info-container::-webkit-scrollbar {
    width: 4px;
}

.location-info-container::-webkit-scrollbar-thumb {
    background-color: #47FFBD;
    border-radius: 10px;
}

.location-info {
    padding: 0 20px;
    overflow-y: auto;
}

.location-name {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 23px;
}

.location-description {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    margin-top: 20px;
}

.show-more {
    font-weight: 400;
    font-size: 14px;
    color: #47FFBD;
    cursor: pointer;
}

.next-events-title {
    font-weight: 600;
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.ant-select-dropdown {
    background: #1E1E2A;
}

.ant-select-selector img {
    display: none;
}

.optionsLocations {
    background: #1E1E2A;
    color: white;
    filter: drop-shadow(0px 4px 21px rgba(0, 0, 0, 0.25));
    padding-right: 15%;
}

.optionsLocations img {
    position: absolute;
    right: 2.5%;
    height: 70%;
    width: 10%;
    top: 15%;
}

.optionsLocations:hover {
    background: rgba(71, 255, 189, 1);
    color: #1E1E2A;
}

.optionsLocations:hover img {
    filter: brightness(0);
}

.optionsLocations.ant-select-item-option-active, .optionsLocations.ant-select-item-option-selected {
    color: #1E1E2A;
    background: rgba(71, 255, 189, 1);
}

.optionsLocations.ant-select-item-option-active img, .optionsLocations.ant-select-item-option-selected img {
    filter: brightness(0);
}

.event-info {
    height: 70px;
    margin-left: 0px;
    margin-bottom: 11px;
    padding: 0 10px;
    background: #1E1E2A;
    border-radius: 12.2652px;
    overflow: hidden;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.event-info-img {
    width: 70.25px;
    height: 53.52px;
    border-radius: 6.6901px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    margin-left: 8px;
}

.event-info-name {
    font-weight: 400;
    font-size: 17px;
    line-height: 18px;
    color: #FFFFFF;
}

.event-info-date {
    font-weight: 400;
    font-size: 12px;
    color: #FFFFFF;
    opacity: 0.3
}

.event-info-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.view-all-events {
    background: #47FFBD;
    border: 0.930304px solid #47FFBD;
    backdrop-filter: blur(15.8152px);
    border-radius: 111.324px;
    width: 100%;
    height: 44px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 44px;
    cursor: pointer;
    color: #3C3B42;
    text-align: center;
    margin-top: 22px;
}

.go-to {
    margin-left: auto;
    margin-right: 10px;
}

@media only screen and (max-width: 700px) {
    .location-info-container {
        height: auto;
        z-index: 10;
        width: 100%;
        left: 0;
        bottom: 0;
        top: auto;
        border-radius: 0;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        padding: 30px 0 15px;
    }

    .location-name {
        margin-bottom: 30px;
        width: 90%;
        line-height: 25px;
    }

    .close-location-info {
        right: 42px;
        top: 20px;
        right: 20px;
    }

    .location-info {
        padding-bottom: 20px;
        padding-top: 20px;
        overflow: unset;
    }

    .event-info {
        height: 70px;
    }

    .event-info-img {
        height: 40px;
    }
}

@media only screen and (max-height: 800px) {
    .location-info-container {
        padding: 50px 0 20px;
    }

    .location-description {
        font-weight: 400;
        font-size: 13px;
        line-height: 17px;
        margin-top: 10px;
    }

    .show-more {
        font-size: 12px;
    }

    .next-events-title {
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 17px;
        line-height: 20px;
    }

    .event-info {
        height: 55px;
    }

    .event-info-img {
        height: 40px;
        width: 60px;
        margin-left: 0;
    }

    .event-info-name {
        font-size: 15px;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        display: -webkit-inline-box;
        text-transform: capitalize;
    }

    .event-info-date {
        font-size: 11px;
    }

    .view-all-events {
        height: 30px;
        font-size: 12px;
        line-height: 30px;
        width: 80%;
        margin: 22px auto auto auto;
    }
}