.sub-container-bank {
    width: 90%;
    margin: auto;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.container-event header {
    top: 0;
}

.container-bannerBank+header+.headerDummy {
    height: 0;
}

.container-banks .headerDummy {
    height: unset;
}

.bankLighting:nth-of-type(1) {
    position: absolute;
    right: -400px;
    width: 800px;
    top: 10vh;
}

.bankLighting:nth-of-type(2) {
    position: absolute;
    left: -400px;
    width: 800px;
    top: 80vh;
}

.bannerBank,
.bannerBank img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.container-events-bank {
    width: 80%;
    margin-left: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.container-events-bank .event {
    width: 31%;
    margin-bottom: 38px;
    margin-right: 0;
}

.container-banks {
    position: relative;
}

.container-banks header {
    top: 0;
}

.container-bannerBank {
    height: 23vw;
}

.bannerBank {
    z-index: -1;
}


/* Ipad Mini & Air */
@media only screen and (min-width: 767px) and (max-width: 820px) {
    .sub-container-bank .container-filters {
        width: 40%;
    }

    .container-events-bank>.event {
        width: calc(98% - 25px);
    }

    .container-bannerBank {
        margin-top: 75px;
    }
}

/* Pantalla chicas y medianas de desktop */
@media only screen and (min-width: 1000px) and (max-width: 1500px) {
    .container-events-bank .event {
        width: 30%;
    }
}

/* Mobile */
@media only screen and (max-width: 700px) {
    .container-banks .headerDummy {
        height: 95px !important;
    }

    .container-bank {
        position: relative;
        margin-top: 2rem;
    }

    .container-bannerBank {
        height: 40vw;
        margin-top: 170px;
    }

    .container-banks header {
        position: fixed;
    }

    .container-bank .container-recommended-filters-made {
        margin: 0px auto 20px;
    }


    .sub-container-bank {
        width: 100%;
    }

    .container-events-bank {
        width: 90%;
        flex-direction: column;
        margin: auto;
    }

    .container-events-bank .event {
        width: 100%;
        margin: 30px 0 0 0;
    }

    .container-filters {
        position: absolute;
        top: 0;
        width: 100% !important;
        height: 100%;
    }
}