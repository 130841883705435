/*Estilos diferentes para restaurant*/
.container-detail-information-restaurant {
    width: 80%;
    margin: auto auto 40px;
    display: flex;
    justify-content: space-between;
    gap: 2%;
}

.container-detail-information-restaurant .container-detail-information {
    margin: 0;
}

.container-detail-information-restaurant div:first-child {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.detail-information-restaurant {
    min-width: 20%;
    width: 35%;
}

.container-detail-information-restaurant div:last-child,
.container-detail-information-restaurant div:first-child {
    background: #1E1E2A;
    border-radius: 8px;
    padding: 25px;
    color: white;
}

.container-detail-information-restaurant div p {
    margin-bottom: 10px !important;
}

.container-detail-information-restaurant div h2 {
    color: white;
    line-height: 21px;
    font-size: 20px;
    margin-bottom: 10px !important;
}

.container-detail-information-restaurant div p.subtilte-information-restaurant {
    font-weight: 900;
    font-size: 16px;
    margin-bottom: 0 !important;
}

.container-detail-information-restaurant .detail-information-restaurant div {
    display: flex;
    flex-direction: unset;
    padding: 0;
    width: unset;
    justify-content: flex-start;
}

.container-detail-information-restaurant div.bands div {
    flex-direction: column;
    gap: 20px;
}

.container-detail-information-restaurant .event-data {
    margin-bottom: 20px;
}

.container-detail-information-restaurant .detail-information-restaurant .event-data .event-data-date {
    flex-direction: column;
    align-items: unset;
}

.container-detail-information-restaurant .header-detail-information {
    flex-direction: unset !important;
    padding: 0 !important;
    justify-content: flex-start !important;
}

.container-detail-information-restaurant .openInfo div p {
    padding-bottom: 0;
}

.container-detail-information-restaurant .openInfo .openInfoDescription,
.container-detail-information-restaurant .openInfo .openInfoDescription div {
    padding: unset;
}

.openInfo .table {
    position: relative;
    width: 100%;
}

.openInfo .table td {
    border: 1px solid #fff;
    padding: 5px;
    text-align: center;
    vertical-align: middle;
}

.redes-restaurant a {
    display: flex;
    gap: 10px;
    margin-right: 10px;
    border-radius: 100px;
    color: white;
}

.redes-restaurant a img {
    margin-right: 0;
}

.container-detail-information-restaurant div:first-child {
    align-items: flex-start;
}

.container-detail-information-restaurant .openInfo div {
    flex-direction: unset;
}

.detail-information-restaurant {
    align-items: flex-start;
}

.servicios {
    padding: 25px 0 !important;
}

.servicios .detail-information-restaurant {
    flex-direction: column !important;
    align-items: start;
}

.servicios .detail-information-restaurant div {
    align-items: center;
}

.container-detail-information {
    justify-content: flex-start !important;
}

.servicios div img {
    margin-right: 10px;
}

.container-detail-information-restaurant:last-child div:last-child div p {
    line-height: 15px;
    font-size: 13px;
}

.container-detail-information-restaurant div:last-child div p {
    margin-bottom: 0 !important;
}

.branches-restaurant {
    display: flex;
    flex-direction: column;
}

.branches-restaurant h2 {
    margin-bottom: 4px !important;
}

.branches-restaurant>div {
    display: flex;
    gap: 0.5rem 0.25rem;
}

.branches-restaurant button {
    background: #1E1E2A;
    padding: 0.50rem 1.5rem;
    border-radius: 50px;
    border: none;
    transition: 0.5s ease;
    cursor: pointer;
    font-weight: 900;
}

.branches-restaurant button:hover,
.branches-restaurant button.selected {
    background: #47FFBD;
    color: #16151E;
}

.redes-restaurant {
    display: flex;
    flex-direction: column;
}

.redes-restaurant h2 {
    margin-right: 20px;
    margin-bottom: 4px !important;
}

/*****************/

.container-event-information {
    position: relative;
    z-index: -0;
}

.container-event-information .headerDummy {
    height: unset !important;
}

.buttonFechas,
.buttonOpenFechas {
    margin-left: 30px;
}

.container-events-recommended {
    width: 90%;
    margin: auto;
}

.container-start {
    position: relative;
    height: 100vh;
}

.container-event-information .container-information {
    height: 100%;
    background: linear-gradient(180deg, rgba(22, 21, 30, 0) 35.5%, #16151E 100%), linear-gradient(0deg, rgba(8, 8, 20, 0.1), rgba(8, 8, 20, 0.1));
    top: 95px;
    margin: auto;
}

.container-event-information .container-start .container-information .information .container-information-slider {
    margin-left: 0 !important;
}

.container-event-information .container-start .container-information .information .container-information-slider div div img {
    margin: 0 10px 0 0;
}

.container-event-information .container-information .wantToGo {
    display: none;
}

.container-event-information .container-information .star {
    position: absolute;
    top: 0;
}

.container-event-information .container-information .theme {
    /*  margin-left: 50px; */
    margin-top: 2px;
}

.redes-share {
    display: flex;
    align-items: center;
    position: absolute;
    background: none;
    border: none;
    bottom: 55px;
    right: 10%;
    border: transparent;
}

.redes-share p {
    margin: 0 5px;
}

.redes-share img:nth-of-type(1) {
    margin: 0 5px;
}

.redes-share a {
    border: 1px solid white;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    cursor: pointer;
    margin: 0 5px;
}

.redes-share a:nth-of-type(1) img {
    margin-left: 6px;
}

.event-data-date h2::first-letter {
    text-transform: capitalize;
}

.container-detail-information {
    width: 80%;
    margin: 0 auto 50px;
    padding: 35px;
    background: #1E1E2A;
    border-radius: 8px;
    flex-direction: column;
    justify-content: center;
}

.header-detail-information {
    margin-bottom: 20px;
}

.buttonInfo,
.buttonOpen {
    background: transparent;
    border: transparent;
    font-size: 20px;
    line-height: 27px;
    color: #A5A5AA;
    margin-right: 20px;
    cursor: pointer;
    padding-bottom: 0px;
}

.container-detail-info {
    justify-content: space-between;
    display: flex;
    width: 100%;
}

.container-detail-info .detail-info-event {
    width: 27%;
    height: 400px;
    overflow-y: scroll;
}

.detail-info-event::-webkit-scrollbar {
    width: 4px;
}

.detail-info-event p {
    margin-right: 10px;
}

.detail-info-event::-webkit-scrollbar-thumb {
    background-color: #47FFBD;
    border-radius: 10px;
}

.detail-info-event span {
    white-space: pre-wrap;
}

.container-slider-event-detail {
    width: 70%;
}

.container-slider-event-detail .slick-slide img {
    width: 100%;
    object-fit: cover;
    object-position: center;
    height: 100%;
}


.buttonOpen {
    border-bottom: 2px solid #47FFBD;
    font-weight: 900;
    color: white;
}

.about-the-event {
    display: none;
}

.openInfo {
    display: flex !important;
    justify-content: center;
}

.openInfo:nth-of-type(2) {
    flex-direction: column;
}

.container-slider-location-detail {
    width: 100%;
    margin-left: 3%;
}

/* .header-detail-information+.openInfo p {
    padding-bottom: 0;
} */

.about-the-event p,
.openInfo p {
    color: white;
    padding-bottom: 20px;
    white-space: pre-wrap;
    font-size: 15px;
}

.openInfo p h1,
.openInfo p h2,
.openInfo p h3,
.openInfo p h4,
.openInfo p h5,
.openInfo p h6 {
    color: white;
}

.detail-info-event div {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 1px solid #A5A5AA;
    margin-bottom: 20px;
}

.detail-info-event div:last-child {
    border: none;
}

.detail-info-event p {
    margin-top: 0;
}

.detail-info-event div:last-child {
    margin-bottom: 0 !important;
}

.detail-info-event div a {
    margin-right: 10px;
}

/* .detail-info-event div a:nth-of-type(2),
.detail-info-event div a:nth-of-type(3),
.detail-info-event div a:nth-of-type(4) {
    margin-right: 10px;
} */

.container-event-information .container-information .information {
    bottom: 120px;
    left: 10%;
    width: 65%;
}

.sub-container-events-recommended {
    display: flex;
    justify-content: space-between;
}

.title-recommended {
    font-family: "Avenir Book";
    font-weight: 500;
    font-size: 32px;
    line-height: 44px;
    color: #FFFFFF;
    text-align: center;
    padding: 30px 0;
}

.detail-info-event p span,
.detail-info-event p span a {
    color: #47FFBD !important;
    opacity: unset;
}

.detail-info-event p {
    color: #A5A5AA;
}

.detail-info-event p,
.detail-info-event p span {
    font-weight: 900;
}

.detail-info-event p span {
    color: #FFFFFF;
    opacity: unset;
}

.detail-info-event .description-block p,
.detail-info-event .description-block div {
    position: relative;
    display: block;
    color: #a5a5aa;
    border: unset;
    margin-bottom: unset;
}

.detail-info-event .description-block p span {
    color: #a5a5aa !important;
    border: unset;
    margin-bottom: unset;
}

.map-where-go {
    width: 80%;
    margin: auto;
}

.redes-share button {
    margin: 0 3px;
}

.map-where-go span {
    font-size: 20px;
    line-height: 23px;
    color: #FFFFFF;
    opacity: 0.6;
    font-weight: 600;
}

.map-where-go p {
    color: #FFFFFF;
    opacity: 0.5;
    font-weight: 900;
    font-size: 14px;
}

.map-where-go h2 {
    color: white;
    margin-top: 10px;
}

.container-map-where-go {
    height: 400px;
    width: 100%;
    position: relative;
    margin-top: 20px;
}

.container-video-slider {
    width: 80%;
    margin: 50px auto;
}

.container-video-slider iframe {
    margin-bottom: 40px;
}

.slider-img-event {
    width: 80%;
    margin: auto auto 80px;
}

.slider-img-event:has(.slick-track:empty) {
    display: none;
}

.slider-img-event .slick-slide div {
    height: 450px;
    width: 98%;
}

.slider-img-event .slick-slide div img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.information-event-select {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin: auto;
    padding-bottom: 50px;
}

.event-data {
    display: flex;
    align-items: flex-start;
    z-index: 1;
}

.information-event-select h2 {
    color: white;
}

.information-event-select p {
    padding-bottom: 5px;
    color: #FFFFFF;
    opacity: 0.5;
    font-size: 14px;
}

.event-data img {
    position: relative;
    margin-right: 10px;
}

.buy-ticket-button {
    background-color: transparent;
    border: 1px solid rgba(71, 255, 189, 1);
    border-radius: 7px;
    height: 45px;
    width: 100%;
    color: rgba(71, 255, 189, 1);
    cursor: pointer;
    transition: .5s all ease-in-out;
    margin-top: 10px;
}

.buy-ticket-button:hover {
    border: 1px solid white;
    color: white;
}

.event-data:nth-of-type(1) {
    width: 25%;
    flex-direction: column;
    z-index: 11;
    position: relative;
}

.restaurants .event-data:nth-of-type(2) {
    flex-direction: column;
    display: none;
}

.restaurants .event-data:nth-of-type(2) .container-fechas-event div {
    color: white;
    font-weight: 600;
    font-size: 17px;
}

.restaurants .event-data:nth-of-type(1) {
    flex-direction: unset;
    width: 100%;
}

.container-fechas-event div {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    height: fit-content;
    overflow: hidden;
}

.container-fechas-event div h2 {
    padding-top: 5px;
    line-height: 16px;
}

.event-data-date .next-function-title {
    padding-bottom: 10px;
}

.event-data-date .next-function {
    opacity: 0.5;
}

.container-fechas-event {
    display: flex;
    align-items: flex-start;
}

.buttonFechas+.event-data-date {
    display: none !important;
}

.buttonFechas,
.buttonOpenFechas {
    font-weight: 400;
    font-size: 14.6793px;
    line-height: 20px;
    color: #FFFFFF;
    opacity: 0.5;
    background-color: transparent;
    border: unset;
    margin-top: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.buttonFechas img,
.buttonOpenFechas img {
    margin-left: 10px;
}


.buttonOpenFechas+.data-fecha-event {
    position: absolute;
    left: 0;
    top: 100px;
    right: 0;
    z-index: 999;
    display: flex !important;
    justify-content: flex-start;
    align-items: center;
    border: transparent;
    border-radius: 0;
    width: 200%;
}

.buttonOpenFechas+.data-fecha-event div h2 {
    padding: 15px 0 10px 0;
}

.triangulo-top {
    margin: 0 auto;
    width: 0;
    height: 0;
    border-right: 15px solid transparent;
    border-top: 15px solid transparent;
    border-left: 15px solid transparent;
    border-bottom: 15px solid #1E1E2A;
    position: absolute !important;
    z-index: 100;
    background: unset !important;
    padding: 0 !important;
    border-radius: 0 !important;
    bottom: 100%;
    left: 10px;
}


.buttonOpenFechas+.data-fecha-event div {
    background: #1E1E2A;
    padding: 40px 40px;
    border-radius: 5px;
    position: relative;
    filter: drop-shadow(0px 4px 21px rgba(0, 0, 0, 0.25));
    max-height: 400px;
    overflow-y: scroll;
}

.buttonOpenFechas+.data-fecha-event div::-webkit-scrollbar {
    width: 5px;
}

.buttonOpenFechas+.data-fecha-event div::-webkit-scrollbar-thumb {
    background-color: #47FFBD;
    border-radius: 10px;
}


.cruz-fecha {
    position: absolute;
    right: 10px;
    top: 12px;
    border: transparent;
    color: #47FFBD;
    background: transparent;
    cursor: pointer;
    font-size: 18px;
    line-height: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cruz-fecha img {
    margin-right: 0;
}

.information-event-select h2 {
    font-size: 16px;
    line-height: 20px;
    padding: 0 0 10px 0;
}

.information-event-select h2::first-letter {
    text-transform: capitalize;
}

.information-event-select h3 {
    color: white;
    font-size: 19px;
    font-weight: 700;
    padding-bottom: 10px;
    border-bottom: 1px solid gray;
}


.slider-location-detail div {
    height: 550px;
}

.slider-event-detail div {
    height: 400px;
}

.slider-location-detail div img,
.slider-event-detail div img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.slider-event-detail .arrowSliderLeft,
.slider-location-detail .arrowSliderLeft {
    left: 0;
}

.event-data:nth-of-type(2) {
    width: 35%;
}

.event-data:nth-of-type(2) .event-data-date:nth-of-type(2) {
    width: 100%;
}

.event-data:nth-of-type(3) {
    width: 25%;
}

.event-data:nth-of-type(3) .event-data-date {
    width: 100%;
}

.sub-container-events-recommended.containerEvents .containerTitleTypeEvent {
    width: 90%;
}

.container-events-recommended {
    width: 100%;
    margin: auto;
    margin-left: auto;
}

.container-events-recommended .containerEvents .containerTitleTypeEvent {
    display: none;
}

.container-events-recommended .sub-container-events-recommended .containerEvents .sliderEvents {
    width: 80%;
    margin: auto;
}

.container-events-recommended .containerEvents .background-hide {
    display: none;
}

.container-events-recommended .containerEvents .event {
    margin-right: auto;
    margin-left: auto;
    width: 95%;
}

.container-events-recommended .containerEvents .arrowSliderLeft {
    left: 0;

}

.container-events-recommended .noSlider .event {
    width: 350px !important;
    margin-right: 10px !important;
    margin-left: 10px !important;
}

.container-events-recommended .noSlider {
    display: flex;
    justify-content: center !important;
}

.information-promotions-event {
    padding: 35px;
    background: #1E1E2A;
    border-radius: 8px;
    width: 80%;
    margin: auto auto 50px;
}

.container-promotions-event {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
}

.information-promotions-event h2 {
    margin-bottom: 20px !important;
    color: white;
}

.img-promotion-event {
    position: relative;
    width: calc(33.3% - 20px);
    display: flex;
    gap: 20px;
    background-color: #16151E;
    border-radius: 5px;
    padding: 20px;
}

.img-promotion-event-open {
    position: absolute;
    top: 130px;
    left: 0;
    display: flex;
    width: 100%;
    height: auto !important;
    gap: 20px;
    background-color: #16151E;
    border-radius: 5px;
    padding: 20px;
    z-index: 1;
}

.img-promotion-event-open h3,
.img-promotion-event-open p {
    display: block !important;
    overflow: visible !important;
}

.img-promotion-event>div {
    width: 100%;
    height: 70px;
    margin-bottom: 10px;
}

.img-promotion-event img {
    width: 70px;
    height: 70px;
    margin-bottom: 10px;
    object-fit: cover;
    border-radius: 10px;
}

.img-promotion-event:last-child {
    border-right: unset;
}

.img-promotion-event h3 {
    color: white;
    font-size: 17px;
    line-height: 20px;
    font-weight: 600;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.img-promotion-event h3::first-letter {
    text-transform: capitalize;
}

.img-promotion-event p {
    margin-top: 10px;
    color: white;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.img-promotion-event button {
    width: 40%;
    color: #47FFBD;
    cursor: pointer;
}

.container-event-information .bannerIntendencia.desktopElement {
    margin: 1.25% auto;
    width: 80%;
}

.container-event-information .bannerIntendencia.desktopElement button {
    width: 100%;
    cursor: pointer;
}

/* Mobile */
@media only screen and (max-width: 700px) {

    /*Estilos diferentes para restaurant*/
    .container-detail-information-restaurant {
        flex-direction: column;
        width: 95%;
        margin: auto auto 20px;
    }


    .restaurants .event-data:nth-of-type(1) .event-data-date:nth-of-type(1) {
        height: auto;
    }

    .restaurants .event-data {
        margin-bottom: 15px;
    }

    .container-detail-information {
        margin-bottom: 20px !important;
    }

    .container-detail-information-restaurant div:last-child,
    .container-detail-information-restaurant div:first-child {
        width: 100%;
    }

    .container-events-recommended .noSlider .event {
        margin-right: 0 !important;
        margin: auto auto 20px;
    }

    .redes-restaurant {
        flex-direction: unset;
        justify-content: flex-start !important;
        align-items: center;
    }

    .redes-restaurant h2 {
        margin-bottom: 0 !important;
    }

    .detail-information-restaurant {
        flex-direction: column;
    }

    .branches-restaurant>div {
        flex-wrap: wrap;
    }

    .information-event-select {
        padding-bottom: 10px;
    }

    .container-detail-information-restaurant div:last-child div p {
        line-height: 15px;
    }

    .container-detail-information-restaurant .detail-information-restaurant .event-data .event-data-date {
        margin-bottom: 20px;
    }

    .container-detail-information-restaurant div:first-child {
        z-index: 20;
    }

    /**************/

    .sub-container-events-recommended .sliderEvents .slick-slider .slick-list {
        margin-left: 23px;
    }

    .container-events-recommended .sub-container-events-recommended .containerEvents .sliderEvents {
        width: calc(97% + 25px);
    }

    .container-events-recommended .sub-container-events-recommended .containerEvents .sliderEvents.noSlider {
        flex-direction: column;
        width: 90%;
        margin: auto;
    }

    .container-start .containerBandMobile {
        height: 100%;
    }

    .container-event-information .container-information {
        width: 100%;
        border-radius: 0 !important;
        top: 55px;
    }

    .container-start {
        height: 65vh;
    }

    .cotnainer-start .containerBandMobile {
        height: 100%;
    }

    .event-data:nth-of-type(3) {
        margin-top: 10px;
    }

    .information-event-select {
        flex-direction: column;
        width: 90%;
        padding-top: 0;
    }

    .event-data:nth-of-type(1) .event-data-date:nth-of-type(1) {
        height: 65px;
    }

    .event-data {
        width: 100% !important;
        justify-content: space-between;
        margin-bottom: 20px;
    }

    .event-data .event-data-date {
        width: 90% !important;
    }

    .container-event-information .container-information .star {
        position: absolute;
        top: unset;
        bottom: 55px;
        width: 30px;
        height: 30px;
        background: rgba(22, 21, 30, 0.6);
        -webkit-backdrop-filter: blur(10);
        backdrop-filter: blur(10);
        display: none;
    }

    .container-information+.star {
        z-index: 100;
        position: absolute;
        bottom: 75%;
        right: 60px;
        background: rgba(22, 21, 30, 0.6);
        -webkit-backdrop-filter: blur(10);
        backdrop-filter: blur(10);
    }

    .container-information+.star svg {
        stroke: #FFFFFF;
    }

    .container-event-information .container-information .theme {
        margin-left: 0;
        margin-top: 0;
        margin-bottom: 5px !important;
        background: #1E1E2A;
        -webkit-backdrop-filter: blur(10);
        backdrop-filter: blur(10);
        width: fit-content;
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        height: 30px;
    }

    .redes-share {
        bottom: 75%;
        right: 5%;
        background: rgba(22, 21, 30, 0.6);
        -webkit-backdrop-filter: blur(10);
        backdrop-filter: blur(10);
        border-radius: 10px;
        padding: 5px;
        height: 40px;
        width: 40px;
        overflow: hidden;
        border: transparent;
    }

    .redes-share-open {
        width: fit-content !important;
    }

    .container-event-information .container-information .information {
        width: auto;
        bottom: 60px;
        left: 7%;
        right: 7.5%;
        background: transparent;
    }

    .information-event-select h2 {
        font-size: 17px;
    }

    .buttonInfo,
    .buttonOpen {
        font-size: 17px;
        padding-bottom: 1px;
    }

    .information-event-select p {
        display: -webkit-box;
        -webkit-box-orient: vertical;
    }

    .buttonOpen {
        border-bottom: 1px solid #47FFBD;
    }

    .buttonFechas,
    .buttonOpenFechas {
        margin-left: 9%;
        width: calc(100% - 10%);
        margin-top: 5px;
    }

    .buttonOpenFechas+.data-fecha-event {
        width: 100%;
    }

    .buttonOpenFechas+.data-fecha-event div {
        top: -20px;
    }

    .header-detail-information {
        display: flex;
    }

    .about-the-event p {
        margin: 0;
        width: 100%;
    }

    .openInfo {
        flex-direction: column;
    }

    .container-slider-location-detail {
        width: 100%;
        margin-left: 0;
    }

    .container-detail-information {
        width: 95%;
        padding: 20px;
        margin: 0 auto 20px;
    }

    .information-promotions-event {
        width: 95%;
    }

    .slider-location-detail div,
    .slider-event-detail div {
        height: 200px;
    }

    .container-detail-info {
        flex-direction: column;
    }

    .detail-info-event {
        width: 100% !important;
    }

    .detail-info-event p {
        padding-bottom: 10px;
    }

    .container-slider-event-detail {
        width: 100%;
        height: 210px;
        margin-top: 30px;
    }

    .map-where-go {
        width: 90%;
    }

    .title-recommended {
        font-size: 25px;
        padding: 30px 0 10px;
        text-align: left;
        margin-left: 5%;
    }

    .container-video-slider {
        width: 90%;
        height: 300px;
        margin: 50px auto 0;
    }

    .slider-img-event .slick-slide div {
        height: 300px;
    }

    .slider-img-event {
        width: 90%;
        margin: auto auto 50px;
    }

    .slider-img-event img {
        border-radius: 10px;
    }

    .sub-container-events-recommended {
        flex-direction: column;
    }

    .sub-container-events-recommended .event {
        width: 97% !important;
        margin-left: 0 !important;
    }

    .information-promotions-event h2 {
        margin-bottom: 10px !important;
    }

    .container-promotions-event {
        justify-content: center
    }

    .img-promotion-event div img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }

    .img-promotion-event {
        border-right: 0;
        width: 100%;
        margin: 10px 0;
    }

    .img-promotion-event p {
        margin-top: 5px;
    }

    .img-promotion-event div img {
        width: 100%;
    }
}

/* Ipad Mini & Air */
@media only screen and (min-width: 767px) and (max-width: 820px) {
    .information-event-select {
        width: 90%;
    }

    .container-event-information .container-information .information {
        left: 5%;
    }

    .redes-share {
        right: 5%;
    }

    .information-event-select {
        flex-wrap: wrap;
    }

    .event-data:nth-of-type(1) {
        width: 50%;
        justify-content: space-between;
    }

    .event-data:nth-of-type(2) {
        width: 48%;
        justify-content: space-between;
    }

    .event-data:nth-of-type(1) .event-data-date:nth-of-type(1) {
        width: 90%;
        height: 49px;
    }

    .event-data:nth-of-type(3) {
        margin-top: 20px;
        width: 50%;
    }

    .information-event-select h2 {
        font-size: 17px;
    }

    .container-information {
        bottom: 10px;
        height: calc(100vh - 75px);
    }

    .container-detail-information,
    .map-where-go,
    .container-video-slider {
        width: 90%;
    }

    .buttonFechas {
        margin-left: 8%;
        width: 92%;
    }
}


/* Ipad Pro */
@media only screen and (min-width: 821px) and (max-width: 1628px) {

    .information-event-select,
    .map-where-go,
    .container-detail-information,
    .container-video-slider,
    .container-detail-information-restaurant,
    .information-promotions-event,
    .container-event-information .bannerIntendencia.desktopElement,
    .slider-img-event,
    .container-events-recommended .sub-container-events-recommended .containerEvents .sliderEvents {
        width: 90%;
    }

    .container-event-information .container-information .information {
        left: 5%;
    }

    .redes-share {
        right: 5%;
    }

    .event-data:nth-of-type(3) {
        width: 30%;
    }

    .sub-container-events-recommended .event {
        width: 90%;
    }

    .container-events-recommended {
        width: 100%;
        margin: unset;
        margin-left: auto;
    }
}