.location-marker {
    position: relative;
    z-index: 1;
}

.location-marker.selected {
    z-index: 2;
}

.location-marker img {
    position: absolute;
    width: 40px;
    left: -20px;
    /* half of the width */
    top: -50px;
    /* full height */
}

.location-marker.selected img {
    width: 50px;
    left: -25px;
    top: -64.09px;
    background-image: url('../../img/background-pin-selected.png');
}

.location-marker.my-location img {
    top: -20px;
}