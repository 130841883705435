/*
.container-searchHeader {
    position: absolute;
    width: 100%;
    position: absolute;
    top: 20%;
}*/


.container-searchHeader {
    height: 54px;
    width: 50%;
}

.searchHeader {
    z-index: 2;
    background: #16151E;
    border-radius: 280px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    background: rgba(30, 30, 42, 0.6);
    backdrop-filter: blur(4.96px);
    letter-spacing: 0.03em;
    font-weight: 800;
    font-size: 16px;
}

.container-search,
.sub-container-searchHeader {
    width: 100%;
    display: flex;
    justify-content: center;
}

.sub-container-searchHeader {
    height: 100%;
    align-items: center;
}

.when,
.type,
.where,
.searchMenu {
    display: flex;
    flex-direction: column;
    background: rgba(22, 21, 30, 0.9);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: 0;
    padding: 20px 0;
    z-index: -2;
    position: absolute;
    transition: 0.5s ease-in-out;
    width: 100%;
    right: 0;
}

.when,
.type,
.where {
    top: -95;
}

.searchMenu {
    top: 95px;
}


.voyButton {
    background-color: #47FFBD !important;
    color: #16151E !important;
    border-radius: 280px;
    transition: .5s all ease-in-out;
    text-align: center;
    background: transparent;
    animation: gradient 5s ease infinite;
    background-size: 300%;
    display: none !important;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}


.searchButton {
    text-decoration: none;
    background-color: transparent;
    border: transparent;
    padding: 15px 0px;
    width: 25%;
    transition: .5s all ease-in-out;
    cursor: pointer;
    position: relative;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
}




.searchButton h4 {
    line-height: 16px;
    color: white;
    letter-spacing: 0.03em;
    font-weight: 800;
    font-size: 16px;
    position: absolute;
    top: calc(50% - 8px);
    width: 100%;
    transition: .5s all ease-in-out;
}

.searchButtonWhen,
.searchButtonType,
.searchButtonWhere {
    background-color: #47FFBD !important;
    border-radius: 280px;
}

.searchButtonWhen h4,
.searchButtonType h4,
.searchButtonWhere h4 {
    color: #16151E !important;
}

.searchButton:hover {
    background-color: #47FFBD;
    border-radius: 280px;
}

.searchButton:hover h4 {
    color: #16151E;
}

.searchButtonWhen .filterSelected,
.searchButtonType .filterSelected,
.searchButtonWhere .filterSelected {
    color: #16151E !important;
}

.searchButton:hover .filterSelected {
    color: #16151E;
}

.searchButton .filterSelected {
    color: white;
}

.searchMenuOption .ant-space-vertical {
    margin-left: 5px;
}

.container-filters-selected {
    margin: auto;
    /* display: flex; */
    /* justify-content: center; */
    flex-wrap: wrap;
}

.filterSelected {
    max-width: 140px;
    overflow: hidden;
    font-size: 13px;
    position: relative;
    margin-right: 3px;
    margin-left: 3px;
    float: left;
    font-weight: 600;
    white-space: nowrap;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
}

.filterSelected::first-letter {
    text-transform: capitalize;
}

.filterSelected::before {
    width: 1px;
    height: 3px;
    right: -2px;
    content: "";
    position: absolute;
    bottom: 4px;
    transform: rotate(24deg);
}

.searchButtonWhen .filterSelected::before,
.searchButtonType .filterSelected::before,
.searchButtonWhere .filterSelected::before {
    background: #000000 !important;
}

.searchButton:hover .filterSelected:before {
    background: #16151E;
}

.searchButton .filterSelected::before {
    background: white;
}

.filterSelected:last-of-type::before {
    width: 0;
    height: 0;
}


.searchMenuOption button {
    color: white;
    border: 1px solid rgba(71, 255, 189, 1);
}

.searchMenu .MuiFormControl-root {
    margin: 0;
    width: 170px;
}

.searchMenu .MuiFormControl-root .MuiOutlinedInput-root .css-1laqsz7-MuiInputAdornment-root button {
    border: transparent;
}

.searchMenuOption {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    padding: 0 20px;
}

.searchMenuOption button {
    font-size: 14px;
    font-weight: 600;
}

.searchButton-where .searchMenuOption {
    justify-content: space-around;
    padding-bottom: 0;
}

.searchButton-type .searchMenuOption {
    padding-bottom: 0;
}

.searchSite {
    background: transparent;
    border: 1px solid white;
    border-radius: 100px;
    margin-right: 10px;
    padding: 0 15px;
    width: 50%;
    background-image: url("/src/img/search.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-position-x: 95%;
    width: 300px;
    color: white;
}

.searchSite.mobileElement {
    margin-bottom: 20px;
}

.searchSite.selected {
    border-color: #47FFBD;
    background: #47FFBD;
}

.searchSite.selected .ant-select-selection-item {
    padding-right: 12.5% !important;
    color: #16151E !important;
}

.searchSite.selected .ant-select-selection-item img {
    display: block;
    position: absolute;
    right: 0;
    height: 70%;
    top: 15%;
    width: 10%;
    filter: brightness(0);
}

.viewMap {
    background: #47FFBD;
    color: #16151E;
    display: flex;
    height: 45px;
    justify-content: center;
    gap: 10px;
    align-items: center;
    border-radius: 100px;
    padding: 0 25px;
    border: 1px solid #47FFBD;
    cursor: pointer;
    transition: .5s all ease-in-out;
    font-size: 13px;
    line-height: 12px;
    margin: 0 10px;
}

.viewMap:hover {
    color: #16151E !important;
}

.searchButton-type .searchMenuOption {
    justify-content: center;
}


.headerDummy+.container-searchHeader,
.headerDummy+.container-searchHeader .container-search {
    display: none;
}

/* Mobile  */
@media only screen and (max-width: 700px) {

    header .container-header .container-searchHeader,
    header .container-header .container-search {
        display: none;
    }

    .headerDummy+.container-searchHeader,
    .headerDummy+.container-searchHeader .container-search {
        display: block
    }

    .when,
    .type,
    .where {
        top: auto !important;
        bottom: -1200px;
    }

    .sub-container-searchHeader,
    .container-searchHeader {
        height: auto;
    }

    .container-searchHeader {
        margin: auto;
        width: 100%;
    }

    .sub-container-searchHeader {
        position: relative;
        width: 100%;
        height: 65px;
    }

    .container-cities {
        margin: 20px 0;
    }

    .container-cities .button {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        width: 90%;
        height: 40px;
        padding: 0;
        border-radius: 100px;
        border: solid 1px #47FFBC;
        background-color: #1E1E2A;
        padding: 5px 0;
    }

    .container-cities .button img {
        width: 40px;
        height: 45px;
        object-fit: contain;
    }

    .searchHeader {
        position: fixed;
        top: 90px;
        width: 90%;
        padding: 7.5px;
        flex-direction: row;
        border-radius: 75px;
        background: #1E1E2A;
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        box-shadow: 0px -30px 20px 30px rgb(0 0 0 / 62.5%);
        gap: 7.5px;
    }

    .searchButton h4 {
        font-size: 14px;
        color: #16151E;
    }

    .searchMenuOption .button,
    .searchMenuOption .button-click {
        margin-top: 10px !important;
        margin-left: 0;
        margin-right: 0;
    }

    .searchMenuOption .ant-space-vertical {
        margin-top: 10px !important;
    }

    .searchButton {
        /* background: linear-gradient(232.85deg, rgba(232, 232, 232, 0.24) 0%, rgba(226, 226, 226, 0.12) 169.39%); */
        background-color: #47FFBD;
        backdrop-filter: blur(10px);
        border-radius: 130px;
        width: 95%;
        padding: 20px 0px;
        height: 50px;
    }

    .searchMenuOption .search-menu-map-arrow {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        background: linear-gradient(232.85deg, rgba(165, 165, 170, 0.6) 0%, rgba(226, 226, 226, 0.12) 169.39%);
        border-radius: 130px;
    }

    .searchMenuOption .search-menu-map-arrow button {
        background: none;
        border: none;
        height: 40px;
        width: 40px;
        border-radius: 100px;
        cursor: pointer;
        margin: 0;
        border-top-left-radius: 130px;
        border-bottom-left-radius: 130px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
    }

    .searchMenuOption .search-menu-map-arrow .searchSite {
        margin-bottom: unset;
        border: none;
        border-top-right-radius: 130px;
        border-bottom-right-radius: 130px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
    }

    .near-location .apply {
        width: 100%;
        margin-top: 20px;
    }

    .container-filters-selected {
        width: 90%;
        flex-wrap: unset;
        display: flex;
        justify-content: center;
    }

    .filterSelected {
        max-width: 100%;
    }

    .voyButton {
        border: unset !important;
    }

    .type-mobile {
        height: 100vh;
        z-index: 300 !important;
    }

    .type-mobile .searchMenuOption {
        margin: 30px auto;
        width: 80%;
    }

    .type .searchMenuOption {
        width: 80%;
    }

    .near-location span {
        color: white;
        font-size: 16px;
    }

    .near-location div {
        margin-top: 10px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .near-location div button {
        width: 48%;
        margin-top: 0 !important;
    }

    .distanciaCerca {
        background-color: transparent;
        border: transparent !important;
        margin: 20px 0 !important;
        color: #47FFBD !important;
        display: flex;
        align-items: center;
    }

    .distanciaCerca img {
        margin-right: 20px;
        background: rgba(71, 255, 189, 0.10);
        border-radius: 10px;
        padding: 5px;
    }

    .searchMenu {
        z-index: 10;
        width: 100%;
        left: 0;
        bottom: 0;
        top: auto;
        /*background: #3C3B42;*/
        border-radius: 0;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        padding: 40px 0 15px;
        position: fixed;
    }

    .container-search .close-button {
        position: absolute;
        right: 25px;
        top: 25px;
        width: 25px;
        height: 25px;
    }
    
    .container-search .close-button img {
        width: 100%;
        height: 100%;
    }

    .searchMenu .MuiFormControl-root {
        width: 100%;
    }

    .searchMenuOption {
        flex-direction: column;
        align-items: unset;
        padding: 0;
        width: 65%;
    }

    .searchMenuOption ul {
        margin: 10px 0;
        margin: 10px 0;
        height: 35px;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .MuiFormControl-root {
        margin: 10px auto;
    }

    .searchMenuOption .ant-picker-range {
        width: 100%;
    }

    .viewMap {
        width: 100%;
        margin: auto;
        justify-content: center;
        margin: 10px 0;
        height: 40px;
        border-radius: 100px;
        padding: 0;
        margin: 0;
    }

    .viewMap img {
        margin-right: 10px;
        margin-bottom: 3px;
    }

    .searchSite {
        width: 100%;
        height: 40px;
    }

    .MuiPickersToolbar-root {
        display: none !important;
    }

    .apply {
        width: 90%;
        margin: auto;
        margin-top: 10px;
        color: #3C3B42 !important;
        background: #47FFBD;
        border: transparent;
        height: 40px;
        font-size: 13px;
        line-height: 13px;
        border-radius: 100px;
        width: 65%;
        padding: 10px 25px;
        cursor: pointer;
    }

    .searchMenuOption .ant-space-vertical {
        margin-left: 0;
    }
}



/* Ipad Mini & Air */
@media only screen and (min-width: 767px) and (max-width: 820px) {
    .searchHeader {
        width: 100%;
    }

    .container-searchHeader {
        width: 75%;
        margin-left: 10px;
    }

    .filterSelected {
        font-size: 11px;
    }
}

/* Ipad Pro */
@media only screen and (min-width: 821px) and (max-width: 1200px) {
    .filterSelected {
        font-size: 11px;
        font-weight: 500;
    }

    .searchButton h4 {
        font-weight: 500;
    }

    .container-searchHeader {
        width: 75%;
        margin-left: 20px;
    }

    .container-filters-selected {
        max-width: 190px;
    }
}