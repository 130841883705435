.container-events-view {
    margin-top: 2rem;
}

.sub-container-event-view {
    display: flex;
    align-items: flex-start;
    width: 95%;
    margin-left: auto;
}

.container-type-event .MuiFormControl-root {
    margin: 0 auto 10px;
}

.filters-view-map {
    border-radius: 100px;
    padding: 0 20px;
    font-size: 13px;
    font-weight: bold;
    height: 40px;
    cursor: pointer;
    background-color: #47FFBD !important;
    border: 1px solid #47FFBD !important;
    color: #434249 !important;
    display: flex;
    align-items: center;
}

.filters-view-map-link  img {
    filter: brightness(0);
    margin-right: 20px;
}

.container-event-type-view {
    width: 80%;
    margin-left: auto;
}

.container-event-type-view>.eventButton {
    float: left;
    width: calc(33.3% - 25px);
    margin-bottom: 25px !important;
}

.container-event-type-view>.event {
    margin-left: 0 !important;
}

.container-event-type-view .events .containerEvents:nth-of-type(1) {
    padding: 0 0 2em;
}

/* Switch vista gastronomy o eventos */
.pricing-toggle {
    background-color: transparent;
    padding: 6px 0px;
    border-radius: 30px;
    display: inline-block;
    border: 1px solid white;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 300px;
    text-align: center;
}

.pricing-toggle [name="pricing"] {
    display: none
}

.radio-button {
    width: 180px;
    overflow: hidden;
}

.pricing-toggle input[type="radio"]+label {
    background-color: transparent;
    color: white;
    position: relative;
    border-radius: 34px;
    cursor: pointer;
    transition: .5s all ease-in-out;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pricing-toggle input[type="radio"]:checked+label {
    background-color: #47FFBD;
    color: #1E1E2A;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pricing-toggle input[type="radio"]:checked+label:nth-of-type(1),
.pricing-toggle input[type="radio"]+label:nth-of-type(1) {
    left: -1px;
}

.pricing-toggle input[type="radio"]:checked+label:nth-of-type(2),
.pricing-toggle input[type="radio"]+label:nth-of-type(2) {
    right: -1px;
}


/* Mobile */
@media only screen and (max-width: 700px) {

    .container-events-view .sub-container-event-view .container-event-type-view>div.event,
    .container-events-view .sub-container-event-view .container-event-type-view>div:not(.events) .event {
        margin-right: -3%;
        margin-left: 3% !important;
        margin-bottom: 25px !important;
    }

    /**********/
    .sub-container-event-view {
        display: unset !important;
    }

    .container-event-view-header {
        position: fixed;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        left: 5%;
        right: 5%;
        width: 90%;
        padding: 7.5px;
        top: 90px;
        height: 65px;
        border-radius: 75px;
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        background-color: #1E1E2A;
        box-shadow: 0px -30px 20px 30px rgb(0 0 0 / 63%);
        z-index: 20;
        gap: 7.5px;
    }

    .container-event-view-header button {
        display: flex;
        align-items: center !important;
        gap: 5px;
    }

    .container-event-view-header>button,
    .container-event-view-header>a,
    .container-event-view-header>.orderBy {
        border: none;
        display: flex;
        justify-content: center;
        background-color: #47FFBD;
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        border-radius: 130px;
        width: 95%;
        padding: 15px 0px;
        height: 100%;
    }

    .container-event-view-header button,
    .container-event-view-header>a,
    .container-event-view-header .orderBy .MuiSelect-select {
        font-family: 'Avenir Book';
        font-size: 14px !important;
        font-weight: bold;
        color: #16151E !important;
    }


    .container-event-view-header img {
        filter: brightness(0);
    }

    .container-event-view-filters {
        position: relative;
        margin: 25px 5%;
    }

    .container-event-type-view {
        width: 100%;
        position: relative;
        z-index: 10;
    }

    .container-events-view {
        position: relative;
        margin: 0;
        padding-top: 55px;
    }

    .container-events-view.sponsors {
        padding-top: 40px;
    }

    /*.sub-container-event-view .container-filters {
        position: fixed;
        width: 100%;
        height: 100vh;
        padding-top: 130px;
    }*/

    .container-event-type-view>.event {
        margin-bottom: 20px !important;
        width: 90%;
        margin: auto;
        float: unset !important;
    }

    .container-events-view .container-recommended-filters-made {
        margin: 0 auto;
    }

    .pricing-toggle input[type="radio"]:checked+label:nth-of-type(2),
    .pricing-toggle input[type="radio"]+label:nth-of-type(2) {
        right: -1px;
    }

    .pricing-toggle input[type="radio"]:checked+label:nth-of-type(1),
    .pricing-toggle input[type="radio"]+label:nth-of-type(1) {
        left: -1px;
    }

    .pricing-toggle {
        z-index: 20;
        width: 300px;
        margin: 0 auto;
    }
}


/* Ipad Mini & Air */
@media only screen and (min-width: 767px) and (max-width: 820px) {
    .sub-container-event-view .container-filters {
        width: 40%;
    }

    .container-event-type-view>.event {
        width: calc(98% - 25px);
    }

    .container-events-view .container-recommended-filters-made {
        margin: 0px auto 80px;
    }

    .pricing-toggle {
        position: absolute;
        top: 340px;
        right: calc(50% - 150px);
        z-index: 40;
    }
}

/* Ipad Pro */
@media only screen and (min-width: 821px) and (max-width: 1200px) {
    .container-event-type-view {
        width: 73%;
        margin-right: 0;
    }

    .sub-container-event-view .container-filters {
        width: 27%;
    }

    .container-event-type-view .events .containerEvents .background-hide {
        width: 4%;
        bottom: 0;
        height: 86%;
    }

    .container-event-type-view>.event {
        width: calc(48.3% - 25px);
    }

    .container-events-view .container-recommended-filters-made {
        margin: 0px auto 80px;
        position: relative;
    }

    .pricing-toggle {
        position: absolute;
        right: calc(50% - 150px);
        z-index: 40;
        top: 60px;
    }
}