
.container-type-event .checkboxFrom .checkboxFrom{
    display: flex;
    align-items: center;
}
.titleCheckbox {
    color: white;
    font-family: "Avenir Book";
    font-size: 14px;
}
/*
.checkboxFrom .MuiSwitch-switchBase{
    padding: 0;
    position: absolute;
    top: -1px;
    bottom: 0;
    right: 20px;
}
.checkboxFrom .css-jsexje-MuiSwitch-thumb{
    width: 12px;
    height: 12px;
}
.checkboxFrom .Mui-checked .css-jsexje-MuiSwitch-thumb{
    color: #47FFBD !important;
}
.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track{
    background-color: #00B473;
}
.checkboxFrom .css-1yjjitx-MuiSwitch-track{
    background: #BBBBBB;
}
.css-julti5-MuiSwitch-root{
    right: 10;
}
.open-switch + .titleCheckbox{
    color: red;
}
*/