.imgEvent {
  width: 100%;
  position: relative;
}

.imagenEvent {
  aspect-ratio: 16 / 9;
  width: 100%;
  /* max-height: 200px;*/
  /*box-sizing: border-box;*/
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  object-fit: cover;
  object-position: center;
}

@supports not (aspect-ratio: 16 / 9) {
  .imagenEvent {
    width: 100%;
    max-height: 190px;
  }
}

.imgEvent .star {
  position: absolute;
  right: 5px;
  top: 10px;
  width: 30px;
  height: 30px;
}

.sliderEvents .slick-slider .slick-list {
  padding-left: 0 !important;
}

.event {
  margin-left: 25px;
}

.infoEvent {
  display: flex;
  flex-direction: column;
  padding: 15px 20px;
  background: #1e1e2a;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  height: 200px;
  justify-content: space-between;
}

.typeEvent {
  display: flex;
  align-items: center;
  gap: 5px;
  color: white;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
}

.containerTitleEvent {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.titleEvent {
  font-size: 20px;
  line-height: 27px;
  color: white;
  margin-top: 5px;
}

.hourEvent {
  margin: 5px 0 5px;
}

.locationEvent {
  margin-bottom: 10px;
}

.hourEvent,
.locationEvent {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #ffffff;
  opacity: 0.6;
}

.hourEvent img,
.locationEvent img,
.typeEvent img {
  margin-right: 5px;
}

.voyEventButton {
  font-weight: 800;
  font-size: 20px;
  line-height: 20px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #16151e;
  border-radius: 8px;
  padding: 10px 15px;
  cursor: pointer;
  transition: 0.5s all ease-in-out;
  margin-top: 10px;
  width: 100% !important;
}

.iconEvent {
  height: 15px;
}

.voyEventButton:hover {
  color: #16151e;
}

.typeEvent.Teatro {
  color: #FF434D;
}

.voyEventButton.Teatro:hover {
  background: #FF434D;
  color: #16151e;
}

.typeEvent.Cine {
  color: #47FFBD;
}

.voyEventButton.Cine:hover {
  background: #47FFBD;
  color: #16151e;
}

.typeEvent.Música {
  color: #2DEAF7;
}

.voyEventButton.Música:hover {
  background: #2DEAF7;
}

.typeEvent.Otros.Eventos {
  color: #FF008A;
}

.voyEventButton.Otros.Eventos:hover {
  background: #FF008A;
}

.typeEvent.Fiestas {
  color: #C53AD5;
}

.voyEventButton.Fiestas:hover {
  background: #C53AD5;
}

.typeEvent.Carnaval,
.typeEvent.Concurso.oficial,
.typeEvent.Tablados,
.typeEvent.Festivales,
.typeEvent.Llamadas {
  color: #6E2CFF;
}

.voyEventButton.Carnaval:hover,
.voyEventButton.Concurso.oficial:hover,
.voyEventButton.Tablados:hover,
.voyEventButton.Festivales:hover,
.voyEventButton.Llamadas:hover {
  background: #6E2CFF;
}

.typeEvent.Locación {
  color: #47FFBD;
}

.voyEventButton.Locación:hover {
  background: #47FFBD;
}

.typeEvent.Oferta.Gastronómica {
  color: #EA7506;
}

.voyEventButton.Oferta.Gastronómica:hover {
  background: #EA7506;
}

.typeEvent.Artista {
  color: #E7D005;
}

.voyEventButton.Artista:hover {
  background: #E7D005;
}

.cocardaBank {
  position: absolute;
  bottom: -1px;
  height: 50px;
  width: 100px;
  object-fit: cover;
  border-top-right-radius: 10px;
}

.locationEvent div,
.hourEvent div {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.locationEvent div::first-letter,
.hourEvent div::first-letter {
  text-transform: capitalize;
}

/* Pantalla chica desktop */
@media only screen and (min-width: 1000px) and (max-width: 1400px) {
  .titleEvent {
    font-size: 17px;
  }

  .hourEvent,
  .locationEvent {
    font-size: 12px;
  }
}

/* Ipad Mini */
@media only screen and (min-width: 700px) and (max-width: 768px) {
  .event {
    margin-right: 0;
    margin-left: 25px;
  }
}

/* Mobile */
@media only screen and (max-width: 490px) {
  .sliderEvents .slick-slider .slick-list {
    margin-left: 25px;
  }

  .event {
    margin-right: 25px;
    margin-left: 0;
  }

  .containerTitleEvent {
    -webkit-line-clamp: 1;
  }

  .voyEventButton.Teatro:hover,
  .voyEventButton.Cine:hover,
  .voyEventButton.Música:hover {
    background: #16151e;
    color: white !important;
  }
}

/* Mobile Pequeño */
@media only screen and (max-width: 375px) {
  .titleEvent {
    font-size: 18px;
    line-height: 24px;
  }

  .hourEvent,
  .locationEvent {
    font-size: 13px;
    line-height: 17px;
  }

  .infoEvent {
    height: 190px;
    padding: 15px 15px;
  }
}